import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Avatar,
  Chip,
  Rating,
  Container,
  CircularProgress,
  Pagination,
  Stack,
  Paper,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useDispatch, useSelector } from "react-redux";
import { NavHashLink } from "react-router-hash-link";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import axios from "axios";

// Styled components remain the same as before
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const FilterContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 200,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1, 4),
}));

const FindPodsClient = () => {
  const token = useAuthToken();
  const intl = useIntl();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  
  // State management
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [initialLoading, setInitialLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [getData, setGetData] = useState([]);
  
  // Filter states
  const [filters, setFilters] = useState({
    podType: '',
    timezone: '',
    podSize: '',
    location: '',
  });

  // Fetch initial pods data
  const fetchInitialPods = async () => {
    if (!token || !user?.data?.[0]) return;

    try {
      const body = {
        emp_id: user.data[0].emp_id,
        Client_Code: user.data[0].client_code,
        podlocation: '',
        podtype: '',
        podSize: '',
        timezone: '',
        manager_type: user.data[0].manager_type,
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTFINDPODS,
        body,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setGetData(response.data.data);
    } catch (error) {
      console.error("Error fetching initial pods:", error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      if (!token || !user?.data?.[0]?.client_code) return;

      try {
        // Fetch subscription data
        const body = {
          client_code: user.data[0].client_code,
        };

        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const endpoint = user.data[0].client_code.includes("PARTNER")
          ? process.env.REACT_APP_PARTNER_FTECHSUBSCCRIPTIONPARTNER
          : process.env.REACT_APP_FETCHSUBSCRIPTIONDATA;

        const response = await axios.post(endpoint, body, { headers });
        setSubscription(response.data.data);
        
        // Fetch initial pods
        await fetchInitialPods();
      } catch (error) {
        console.error("Error initializing data:", error);
      } finally {
        setInitialLoading(false);
      }
    };

    initializeData();
  }, [token]);

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const applyFilters = async () => {
    setFilterLoading(true);
    const body = {
      emp_id: user.data[0].emp_id,
      Client_Code: user.data[0].client_code,
      podlocation: filters.location,
      podtype: filters.podType,
      podSize: filters.podSize,
      timezone: filters.timezone,
      manager_type: user.data[0].manager_type,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTFINDPODS,
        body,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setGetData(response.data.data);
    } catch (error) {
      console.error("Error applying filters:", error);
    } finally {
      setFilterLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);

  if (initialLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (subscription === "There Are No subscriction data.") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography variant="h5">
          {intl.formatMessage({ id: "noSubscription" })}
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 ,marginTop:"5rem"}}>
      <Typography variant="h4" align="center" gutterBottom>
        {intl.formatMessage({ id: "findBestPod" })}
      </Typography>

      <FilterContainer elevation={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <StyledFormControl fullWidth>
              <InputLabel>Time Zone</InputLabel>
              <Select
                name="timezone"
                value={filters.timezone}
                onChange={handleFilterChange}
                label="Time Zone"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Indian standard time">Indian Standard Time</MenuItem>
                <MenuItem value="Eastern standard time">Eastern Standard Time</MenuItem>
                <MenuItem value="Central standard time">Central Standard Time</MenuItem>
                <MenuItem value="Mountain standard time">Mountain Standard Time</MenuItem>
                <MenuItem value="Pacific standard time">Pacific Standard Time</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StyledFormControl fullWidth>
              <InputLabel>Pod Size</InputLabel>
              <Select
                name="podSize"
                value={filters.podSize}
                onChange={handleFilterChange}
                label="Pod Size"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>

                <MenuItem value="5">5</MenuItem>
                <MenuItem value="7">7</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StyledFormControl fullWidth>
              <InputLabel>Pod Type</InputLabel>
              <Select
                name="podType"
                value={filters.podType}
                onChange={handleFilterChange}
                label="Pod Type"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Saas">SaaS</MenuItem>
                <MenuItem value="Web app">Web App</MenuItem>
                <MenuItem value="Java Developer">Java Developer</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StyledFormControl fullWidth>
              <InputLabel>Location</InputLabel>
              <Select
                name="location"
                value={filters.location}
                onChange={handleFilterChange}
                label="Location"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="usa">Asia</MenuItem>
                <MenuItem value="india">North America</MenuItem>
                <MenuItem value="canada">Latin America</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <StyledButton
              variant="contained"
              color="primary"
              onClick={applyFilters}
              startIcon={<WorkIcon />}
              disabled={filterLoading}
            >
              {filterLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Apply Filters'
              )}
            </StyledButton>
          </Grid>
        </Grid>
      </FilterContainer>

      {filterLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {currentItems.length > 0 ? (
              currentItems.map((pod) => (
                <Grid item xs={12} sm={6} md={4} key={pod.id}>
                  <StyledCard>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Avatar
                          src={pod.picture || '/defaultimg.png'}
                          sx={{ width: 56, height: 56, mr: 2 }}
                        />
                        <Box>
                          <Typography variant="h6">{pod.podName}</Typography>
                          <Rating value={Number(pod.avgrating) || 0} readOnly precision={0.5} />
                        </Box>
                      </Box>

                      <Chip
                        label={pod.Client_Code === user.data[0].Client_Code ? "Internal Pod" : "External Pod"}
                        color={pod.Client_Code === user.data[0].Client_Code ? "primary" : "error"}
                        sx={{ mb: 2 }}
                      />

                      <Divider sx={{ my: 2 }} />

                      <Stack spacing={1}>
                        <Box display="flex" alignItems="center">
                          <LocationOnIcon sx={{ mr: 1 }} />
                          <Typography>{pod.location}</Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                          <GroupIcon sx={{ mr: 1 }} />
                          <Typography>Size: {pod.podsize}</Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                          <MonetizationOnIcon sx={{ mr: 1 }} />
                          <Typography>
                            Rate: {user.data[0].manager_type !== "PartnerClient" 
                              ? pod.pototalrate 
                              : pod.pod_rate}
                          </Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                          <WorkIcon sx={{ mr: 1 }} />
                          <Typography>Type: {pod.podtype}</Typography>
                        </Box>
                      </Stack>

                      <Button
                        component={NavHashLink}
                        to={`/FindPodDetailsClient/${pod.id}`}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 3 }}
                      >
                        {intl.formatMessage({ id: "viewPodDetails" })}
                      </Button>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  No pods found matching your criteria
                </Typography>
              </Grid>
            )}
          </Grid>

          {getData.length > itemsPerPage && (
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(getData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default FindPodsClient;