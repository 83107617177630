
import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Avatar,
  Chip,
  Tooltip,
  CircularProgress,
  Alert,
  AlertTitle,
  Breadcrumbs,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Stack,
  Badge,
  LinearProgress,
  Fade,
  Collapse,
  useTheme,
  useMediaQuery,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, 
  TableRow,
  Checkbox,
  

} from '@mui/material';

// Icons
import {
  Edit as EditIcon,
  Photo as PhotoIcon,

  Save as SaveIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  Person as PersonIcon,
  PersonAdd as PersonAddIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
  Language as LanguageIcon,
  Schedule as ScheduleIcon,
  Speed as SpeedIcon,
  Code as CodeIcon,
  Assessment as AssessmentIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  ErrorOutline as ErrorIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import S3FileUpload from 'react-s3';

import AWS from 'aws-sdk';
import axios from 'axios';
import moment from 'moment';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import defaultimg from './assets/defaultimge.png';
import ImageUploadComponent from './ImageUploadComponent';
import { useSearchParams } from 'react-router-dom';
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
const ImageUploadWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 120,
  height: 120,
  margin: '0 auto',
  marginBottom: theme.spacing(3),
}));

const ImagePlaceholder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '50%',
}));

const UploadIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  bottom: -8,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[400],
  }
}));
// Styled Components
const PageContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  margin:"auto",
  marginTop:"5rem",
  paddingBottom: theme.spacing(8),
  minHeight: '100vh',
  background: 'linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%)',
}));

const MainCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: 16,
  boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
  overflow: 'visible',
  background: '#fff',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 32px 0 rgba(34, 41, 47, 0.15)',
  },
}));

const FormSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
  borderRadius: 12,
  backgroundColor: '#f8fafc',
  border: '1px solid rgba(0, 0, 0, 0.05)',
}));

const PodImageUpload = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 120,
  height: 120,
  margin: '0 auto',
  marginBottom: theme.spacing(3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: '4px solid #fff',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  cursor: 'pointer',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const UploadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  bottom: -8,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: '#fff',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f8fafc',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: '0 0 0 2px rgba(0, 180, 216, 0.2)',
    },
  },
}));

const StyledSelect = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: '#fff',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f8fafc',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: '0 0 0 2px rgba(0, 180, 216, 0.2)',
    },
  },
}));

const ActionButton = styled(Button)(({ variant = 'contained', color = 'primary' }) => ({
  borderRadius: 8,
  padding: '8px 24px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: variant === 'contained' ? '0 4px 12px rgba(0, 180, 216, 0.2)' : 'none',
  '&:hover': {
    boxShadow: variant === 'contained' ? '0 6px 16px rgba(0, 180, 216, 0.3)' : 'none',
  },
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiBreadcrumbs-separator': {
    margin: theme.spacing(0, 1),
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const StatusChip = styled(Chip)(({ status }) => {
  const getStatusColor = () => {
    const colors = {
      active: {
        bg: '#4caf50',
        text: '#fff'
      },
      inactive: {
        bg: '#f44336',
        text: '#fff'
      },
      pending: {
        bg: '#ff9800',
        text: '#fff'
      }
    };
    return colors[status?.toLowerCase()] || colors.active;
  };

  const statusColor = getStatusColor();
  return {
    backgroundColor: statusColor.bg,
    color: statusColor.text,
    fontWeight: 600,
    '& .MuiChip-icon': {
      color: statusColor.text,
    },
  };
});

// S3 Configuration
const s3Config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

// Utility Functions
const formatDate = (date) => moment(date).format('MMM DD, YYYY');

const getStatusIcon = (status) => {
  switch (status?.toLowerCase()) {
    case 'active':
      return <CheckCircleIcon />;
    case 'inactive':
      return <ErrorIcon />;
    case 'pending':
      return <WarningIcon />;
    default:
      return <CheckCircleIcon />;
  }
};

// Enhanced image upload functionality with proper error handling and validation

// const generateImageUrl = async (key, s3) => {
//   if (!key) return null;
//   try {
//     return await s3.getSignedUrl('getObject', {
//       Bucket: s3Config.bucketName,
//       Key: key,
//       Expires: 3600
//     });
//   } catch (error) {
//     console.error('Error generating image URL:', error);
//     return null;
//   }
// };

// Constants
const POD_TYPES = [
  { value: 'SaaS', label: 'SaaS' },
  { value: 'Web + Mobile App', label: 'Web + Mobile App' },
  { value: 'Web App', label: 'Web App' },
  { value: 'Mobile App', label: 'Mobile App' },
  { value: 'Data & AI', label: 'Data & AI' }
];

const TECH_STACKS = [
  { value: 'MEAN', label: 'MEAN Stack' },
  { value: 'MERN', label: 'MERN Stack' },
  { value: 'LAMP', label: 'LAMP Stack' },
  { value: 'MEVN', label: 'MEVN Stack' }
];

const POD_SIZES = [
  { value: '3', label: '3 Members' },
  { value: '5', label: '5 Members' },
  { value: '7', label: '7 Members' },
  { value: '9', label: '9 Members' }
];

const PRIMARY_LANGUAGES = [
  'English', 'Spanish', 'French', 'Portuguese', 'German',
  'Italian', 'Chinese', 'Japanese', 'Russian', 'Arabic',
  'Hindi', 'Korean', 'Turkish', 'Dutch', 'Greek'
];

const TIMEZONES = [
  'Indian Standard Time',
  'Eastern Standard Time',
  'Central Standard Time',
  'Mountain Standard Time',
  'Pacific Standard Time'
];

const MEMBER_ROLES = [
  { value: 'Lead', label: 'Team Lead' },
  { value: 'ScrumMaster', label: 'Scrum Master' },
  { value: 'ProductOwner', label: 'Product Owner' },
  { value: 'Developer', label: 'Developer' },
  { value: 'Designer', label: 'Designer' },
  { value: 'QA', label: 'Quality Assurance' }
];

const AVAILABILITY_OPTIONS = [
  { value: 'fulltime', label: 'Full Time' },
  { value: 'parttime', label: 'Part Time' }
];


// Enhanced Snackbar with custom styling


// Error Boundary Component
class PodManagementErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Pod Management Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ 
          p: 3, 
          textAlign: 'center',
          color: 'error.main' 
        }}>
          <ErrorIcon sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            Something went wrong
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}




// Constants
const POD_ROLES = ['Scrum Master', "Front-end developer", "Backend developer", "Data engineer", "Data Scientist", "AI Engineer", "Data analyst", "ETL Engineer", "JavaScript Developer", "Python Developer", 'Lead', 'Product Owner', 'Developer', 'Tester', 'DevOps'];
const REGIONS = ['Asia Pacific', 'Latin America', 'North America'];
const PRIMARY_SKILLS = ['html', 'css', 'JavaScript', 'Adobe Illustrator', 'ReactJS', 'AngularJS', 'Java', 'Node.js', 'Vue', 'Next', 'Typescript', 'Gatsby', 'Three.js'];
const SECONDARY_SKILLS = ['Java', 'Python', 'PHP', 'SQL', 'Git', 'c++', 'Perl', 'AngularJS', 'Kotlin', 'AWS', 'NodeJs', 'Azure'];

// Main Component
const ClientManagePod = () => {
  
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const token = useAuthToken();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector(state => state.auth);
  const { podid } = location.state || {};
  const [editingMember, setEditingMember] = useState(null);
const [editedMemberData, setEditedMemberData] = useState({
  Pod_Roles: '',
  time_zone: '',
  region: '',
  Primary_Skill: '',
  Secondary_Skill: '',
  Availability: ''
});

// Fetch both existing team members and available candidates


    // State for error handling
    const [errors, setErrors] = useState({
      main: '',
      upload: '',
      save: ''
    });
 console.log('podid->',podid)
  // State Management
  const [podState, setPodState] = useState({
    podName: '',
    podDescription: '',
    podtype: '',
    techStack: '',
    podSize: '',
    primaryLanguage: '',
    primaryTimezone: '',
    podRate: '',
    skills: [],
    candidatesid: [],
    status: 'active'
  });


  const [openSizeDialog, setOpenSizeDialog] = useState(false);
  const [pendingSize, setPendingSize] = useState(null);

    // Function to show error
    const showError = (type, message) => {
      setErrors(prev => ({
        ...prev,
        [type]: message
      }));
    };
  
    // Function to clear error
    const clearError = (type) => {
      setErrors(prev => ({
        ...prev,
        [type]: ''
      }));
    };
  

  // Function to validate pod size change
  const validatePodSize = (newSize) => {
    const currentMemberCount = teamMembers.length;
    const newSizeNum = parseInt(newSize);

    if (currentMemberCount > newSizeNum) {
      showError('main', `Cannot reduce pod size to ${newSize}. Current team has ${currentMemberCount} members.`);
      return false;
    }
    clearError('main');
    return true;
  };

 

  // Function to handle adding team members
  const handleAddTeamMember = (newMember) => {
    const currentSize = parseInt(podState.podSize);
    if (teamMembers.length >= currentSize) {
      setError(`Cannot add more members. Pod size limit (${currentSize}) reached.`);
      return false;
    }
    setTeamMembers([...teamMembers, newMember]);
    return true;
  };

  const renderError = (type) => {
    if (!errors[type]) return null;
    
    return (
      <Alert severity="error" onClose={() => clearError(type)} sx={{ mb: 2 }}>
        <AlertTitle>Error</AlertTitle>
        {errors[type]}
      </Alert>
    );
  };
  const SizeChangeDialog = () => (
    <Dialog 
      open={openSizeDialog} 
      onClose={() => setOpenSizeDialog(false)}
    >
      <DialogTitle>Cannot Reduce Pod Size</DialogTitle>
      <DialogContent>
        <Typography>
          Current team has {teamMembers.length} members. You need to remove some team members before reducing the pod size to {pendingSize}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenSizeDialog(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );


  const [imageState, setImageState] = useState({
    imageUrl: '',
    imageFile: null,
    imageKey: '',
    isUploading: false
  });

  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [loading, setLoading] = useState({
    page: true,
    save: false,
    upload: false
  });
  const [error, setError] = useState({
    main: null,
    upload: null,
    save: null
  });
  const [success, setSuccess] = useState({
    save: false,
    upload: false
  });
  const [openTeamDialog, setOpenTeamDialog] = useState(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [availableCandidates, setAvailableCandidates] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    title: '',
    message: '',
    onConfirm: () => {}
  });
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedPrimarySkill, setSelectedPrimarySkill] = useState('');
  const [selectedSecondarySkill, setSelectedSecondarySkill] = useState('');
  const [selectedAvailability, setSelectedAvailability] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
    key: ''
  });
  // Add this component inside your ClientManagePod component
const ConfirmationDialog = () => {
  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {confirmDialog.title}
      </DialogTitle>
      
      <DialogContent>
        <DialogContentText>
          {confirmDialog.message}
        </DialogContentText>
      </DialogContent>
      
      <DialogActions sx={{ p: 2 }}>
        <Button 
          variant="outlined"
          onClick={() => setOpenConfirmDialog(false)}
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            confirmDialog.onConfirm();
            setOpenConfirmDialog(false);
          }}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};



// Example usage in your remove member function:

  const showSnackbar = (message, severity = 'info') => {
  
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  // S3 Instance
  const s3 = useMemo(() => new AWS.S3(s3Config), []);

  // Initial Data Fetch
  useEffect(() => {
    if (token && podid) {
      fetchPodDetails();
    }
  }, [token, podid]);

// Fetch Pod Details

const fetchTeamMembers = async () => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_CLIENT_PODCANDIDATEFETCHING,
      { pods_id: podid },
      { headers: { Authorization: token } }
    );
    setTeamMembers(response.data.data || []);
  } catch (error) {
    console.error('Error fetching team members:', error);
    showSnackbar('Failed to fetch team members', 'error');
  }
};
const fetchAvailableCandidates = async () => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_CLIENT_FETCHCANDIDATESBASEDONPODS,
      { pod_id: podid },
      { headers: { Authorization: token } }
    );
    setAvailableCandidates(response.data.data || []);
  } catch (error) {
    console.error('Error fetching available candidates:', error);
  }
};
useEffect(() => {
  if (podid && token) {
    fetchTeamMembers();
    fetchAvailableCandidates();
  }
}, [podid, token]);
// 2. Add Team Members Dialog with proper checks
const AddTeamMembersDialog = () => {
  const availableSlots = parseInt(podState.podSize) - teamMembers.length;

  return (
    <Dialog
      open={openTeamDialog}
      onClose={() => setOpenTeamDialog(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Add Team Members ({availableSlots} slots available)
      </DialogTitle>
      <DialogContent>
        {availableSlots <= 0 ? (
          <Alert severity="warning" sx={{ mt: 2 }}>
            <AlertTitle>Maximum Size Reached</AlertTitle>
            Cannot add more members. Please increase pod size or remove existing members.
          </Alert>
        ) : (
          // Your existing team member selection UI
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox 
                      checked={selectedCandidates.length === Math.min(availableCandidates.length, availableSlots)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCandidates(
                            availableCandidates
                              .slice(0, availableSlots)
                              .map(c => c.c_id)
                          );
                        } else {
                          setSelectedCandidates([]);
                        }
                      }}
                    />
                  </TableCell>
                  {/* ... rest of your table headers */}
                </TableRow>
              </TableHead>
              <TableBody>
                {availableCandidates.map((candidate) => (
                  <TableRow key={candidate.c_id}>
                    <TableCell padding="checkbox">
                      <Checkbox 
                        checked={selectedCandidates.includes(candidate.c_id)}
                        onChange={() => {
                          if (selectedCandidates.includes(candidate.c_id)) {
                            setSelectedCandidates(
                              selectedCandidates.filter(id => id !== candidate.c_id)
                            );
                          } else if (selectedCandidates.length < availableSlots) {
                            setSelectedCandidates([...selectedCandidates, candidate.c_id]);
                          } else {
                            setError(`Cannot select more than ${availableSlots} members`);
                          }
                        }}
                        disabled={!selectedCandidates.includes(candidate.c_id) && 
                                selectedCandidates.length >= availableSlots}
                      />
                    </TableCell>
                    {/* ... rest of your candidate row cells */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenTeamDialog(false)}>
          Cancel
        </Button>
        <Button 
          variant="contained"
          onClick={handleAddTeamMembers}
          disabled={selectedCandidates.length === 0 || availableSlots <= 0}
        >
          Add Selected ({selectedCandidates.length})
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// 3. Edit Role Dialog with proper checks
const EditRoleDialog = () => {
  if (!selectedMember) return null;

  return (
    <Dialog
      open={openRoleDialog}
      onClose={() => setOpenRoleDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Edit Team Member Role</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                label="Role"
              >
                {MEMBER_ROLES.map(role => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Availability</InputLabel>
              <Select
                value={selectedAvailability}
                onChange={(e) => setSelectedAvailability(e.target.value)}
                label="Availability"
              >
                {AVAILABILITY_OPTIONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenRoleDialog(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleUpdateRole}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add this debug function to track state changes
// Updated image upload handler with proper state management


// Updated save handler with image key handling
const [Image, setImage] = useState(""); 

// Add this initialization in your component
useEffect(() => {
  // Initialize imageState when component mounts or podState.picture changes
  if (podState.picture) {
    setImageState(prev => ({
      ...prev,
      imageKey: podState.picture,
      imageUrl: `https://${s3Config.bucketName}.s3.${s3Config.region}.amazonaws.com/${podState.picture}`,
    }));
  }
}, [podState.picture]);
const getImageKey = (imageState, podState) => {
  // If we have a new uploaded image, use that
  if (imageState.imageKey) {
    return imageState.imageKey;
  }
  // If we have an existing image in pod state, use that
  if (podState.picture) {
    return podState.picture;
  }
  // Otherwise, we have no image
  return "";
};

// Updated save handler with proper image key handling
const handleSave = async () => {
  setLoading(prev => ({ ...prev, save: true }));
  setError(prev => ({ ...prev, save: null }));

  try {
    const currentImageKey = getImageKey(imageState, podState);
    
    // Log the current states and image key for debugging
    console.log('Current States:', {
      imageState,
      podState,
      currentImageKey
    });
    // Log states before creating payload
    console.log('Current imageState:', imageState);
    console.log('Current podState:', podState);

    // Get the image key from either state
    const imageKey = imageState.imageKey || podState.picture || "";

    console.log('Using image key for payload:', imageKey);

    const payload = {
      podName: podState.podName,
      pod_rate: podState.podRate || "",
      podtype: podState.podtype || "",
      techStack: podState.techStack || "",
      skills: podState.skills || [],
      podDescription: podState.podDescription || "",
      primary_language: podState.primaryLanguage || "",
      primary_timezone: podState.primaryTimezone || "",
      canID: user.data[0].c_id,
      id: podid,
      candidatesid: [],
      picture: currentImageKey, // Use the determined image key
      podsize: podState.podSize || "",
    };

    console.log('Sending payload:', payload);

    const response = await axios.put(
      process.env.REACT_APP_CLIENT_PPODCLIENTUPDATEPOD,
      payload,
      { headers: { Authorization: token } }
    );

    if (response.status === 200) {
      setSuccess(prev => ({ ...prev, save: true }));
      showSnackbar('Pod updated successfully', 'success');
      setTimeout(() => {
        history.push('/FindPodsDetails');
      }, 2000);
    }
  } catch (error) {
    console.error('Error saving pod details:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Failed to save pod details';
    setError(prev => ({
      ...prev,
      save: errorMessage
    }));
    showSnackbar(errorMessage, 'error');
  } finally {
    setLoading(prev => ({ ...prev, save: false }));
  }
};

// Add this useEffect to initialize AWS config and set the existing image state
// Add this useEffect to initialize AWS config and set the existing image state
// Add this useEffect to initialize AWS config and set the existing image state
useEffect(() => {
  // Initialize AWS configuration
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: new AWS.Credentials({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    })
  });

  // Initialize image URL if pod has an existing image
  if (podState.picture) {
    setImageState({
      imageUrl: `https://${process.env.REACT_APP_BUCKETNAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${podState.picture}`,
      imageKey: podState.picture,
      isUploading: false,
      error: null
    });
  }
}, [podState.picture, process.env.REACT_APP_AWS_REGION, process.env.REACT_APP_BUCKETNAME]);


// Update the image upload handler
// At the top with other styled components


// Update the handleImageUpload function to show immediate preview
const handleImageUpload = (e) => {
  const file = e.target.files?.[0];
  if (!file) return;

  // Create and show immediate preview
  const previewUrl = URL.createObjectURL(file);
  setImageState(prev => ({
    ...prev,
    imageUrl: previewUrl, // Set local preview URL
    isUploading: true,
    error: null
  }));

  S3FileUpload.uploadFile(file, config)
    .then((data) => {
      // Keep the same preview URL until we confirm S3 URL works
      const location = data.key;
      const s3Url = `https://globldata.s3.us-east-1.amazonaws.com/${encodeURIComponent(location)}`;
      
      // Test if S3 URL is accessible before switching
      const img = new Image();
      img.onload = () => {
        setImageState(prev => ({
          ...prev,
          imageKey: location,
          imageUrl: s3Url,
          isUploading: false
        }));
        URL.revokeObjectURL(previewUrl); // Clean up only after confirming S3 URL works
      };
      
      img.onerror = () => {
        // Keep the preview URL if S3 URL fails
        setImageState(prev => ({
          ...prev,
          imageKey: location,
          imageUrl: previewUrl,
          isUploading: false
        }));
      };
      
      img.src = s3Url;

      setPodState(prev => ({
        ...prev,
        picture: location
      }));

      showSnackbar('Image uploaded successfully', 'success');
    })
    .catch((err) => {
      console.error('Error uploading image:', err);
      // Keep showing the preview even if upload fails
      setImageState(prev => ({
        ...prev,
        isUploading: false,
        error: 'Failed to upload image',
        imageUrl: previewUrl // Keep the preview URL on error
      }));
      showSnackbar('Failed to upload image', 'error');
    });
};
// Update the image display section


const renderImageUpload = () => (
  <Box sx={{ 
    position: 'relative', 
    width: 120, 
    height: 120, 
    margin: '0 auto',
    marginBottom: "3" }}>
    {imageState.isUploading && (
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2
      }}>
        <CircularProgress size={40} />
      </Box>
    )}

    <Avatar
      src={imageState.imageUrl || defaultimg}
      alt={podState.podName || 'Pod Image'}
      sx={{
        width: '100%',
        height: '100%',
        border: '4px solid #fff',
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        backgroundColor: theme.palette.grey[100]
      }}
    />

    <IconButton
      component="label"
      disabled={imageState.isUploading}
      sx={{
        position: 'absolute',
        right: -8,
        bottom: -8,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        }
      }}
    >
      <input
        type="file"
        hidden
        accept="image/jpeg,image/png,image/gif"
        onChange={handleImageUpload}
      />
      <CloudUploadIcon />
    </IconButton>
  </Box>
);
// Add this to your fetchPodDetails function
// Add this function near the top of your component



// Update the image display section

// Update your fetchPodDetails function
const fetchPodDetails = async () => {
  setLoading(prev => ({ ...prev, page: true }));
  try {
    const response = await axios.post(
      process.env.REACT_APP_CLIENT_GETPODID,
      { id: podid },
      { headers: { Authorization: token } }
    );

    if (response.data?.data) {
      const { podDetails } = response.data.data;
      console.log('Pod picture:', podDetails.picture);
      
      // Construct S3 URL
     
      if (podDetails?.picture) {
        const s3Url = getS3ImageUrl(podDetails.picture);
        
        if (s3Url) {
          setImageState({
            imageUrl: s3Url,
            imageKey: podDetails.picture,
            isUploading: false,
            error: null
          });
        }
      }

      // Set pod state
      setPodState({
        podName: podDetails?.podName || '',
        podDescription: podDetails?.podDescription || '',
        podtype: podDetails?.podtype || '',
        techStack: podDetails?.techStack || '',
        podSize: podDetails?.podsize?.toString() || '',
        primaryLanguage: podDetails?.primary_language || '',
        primaryTimezone: podDetails?.primary_timezone || '',
        podRate: podDetails?.pod_rate || '',
        skills: podDetails?.skills || [],
        candidatesid: [],
        status: podDetails?.status || 'active',
        picture: podDetails?.picture || '' // Store the picture key here
      });

    }
 } catch (error) {
    console.error('Error fetching pod details:', error);
    setError(prev => ({
      ...prev,
      main: 'Failed to load pod details. Please try again.'
    }));
  } finally {
    setLoading(prev => ({ ...prev, page: false }));
  }
};
const getS3ImageUrl = (imageKey) => {
  if (!imageKey) return null;
  
  try {
    // Hardcode the known values instead of using environment variables
    const encodedKey = encodeURIComponent(imageKey);
    const url = `https://globldata.s3.us-east-1.amazonaws.com/${podState.picture}`;
    console.log('Constructed S3 URL:', url);
    return url;
  } catch (error) {
    console.error('Error constructing S3 URL:', error);
    return null;
  }
};
// Update the image display section


// Updated Image Preview Component
const ImageUploadPreview = () => {
  return (
    <Box sx={{ 
      position: 'relative',
      width: 120,
      height: 120,
      margin: '0 auto',
      marginBottom: 3
    }}>
      <Avatar
        src={imageState.imageUrl || defaultimg}
        alt={podState.podName}
        sx={{
          width: '100%',
          height: '100%',
          opacity: imageState.isUploading ? 0.6 : 1,
          border: '4px solid #fff',
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          backgroundColor: 'grey.100'
        }}
      />
      {imageState.isUploading && (
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1
        }}>
          <CircularProgress size={40} />
        </Box>
      )}
      <IconButton
        component="label"
        disabled={loading.upload || imageState.isUploading}
        sx={{
          position: 'absolute',
          right: -8,
          bottom: -8,
          backgroundColor: 'primary.main',
          color: '#fff',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
          '&.Mui-disabled': {
            backgroundColor: 'grey.400'
          }
        }}
      >
        <input
          type="file"
          hidden
          accept="image/jpeg,image/png,image/gif"
          onChange={handleImageUpload}
        />
        {loading.upload ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <CloudUploadIcon />
        )}
      </IconButton>
    </Box>
  );
};
// Add this at the top of your component with other styled components
const PodImageUpload = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 120,
  height: 120,
  margin: '0 auto',
  marginBottom: theme.spacing(3),
  '& .MuiAvatar-root': {
    border: `4px solid ${theme.palette.background.paper}`,
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    backgroundColor: theme.palette.grey[100]
  }
}));


 // Modified handleInputChange to include size validation
 const handleInputChange = (field) => (event) => {
  const newValue = event.target.value;

  if (field === 'podSize') {
    const isValid = validatePodSize(newValue);
    if (!isValid) {
      setPendingSize(newValue);
      setOpenSizeDialog(true);
      return;
    }
  }

  setPodState(prev => ({
    ...prev,
    [field]: newValue
  }));
  clearError('main');
};
const handleSkillChange = (selectedOptions) => {
setSelectedSkills(selectedOptions);
setPodState(prev => ({
...prev,
skills: selectedOptions.map(option => ({
skill: option.label
}))
}));
};

// Utility Functions
const calculateMemberCompletion = (member) => {
let completedFields = 0;
const totalFields = 5; // role, primary skill, secondary skill, availability, timezoneif (member.role) completedFields++;
if (member.primarySkill) completedFields++;
if (member.secondarySkill) completedFields++;
if (member.availability) completedFields++;
if (member.timezone) completedFields++;

return (completedFields / totalFields) * 100;};
const handleSelectAllClick = (event) => {
if (event.target.checked) {
const availableSlots = parseInt(podState.podSize) - teamMembers.length;
const newSelected = availableCandidates
.slice(0, availableSlots)
.map(c => c.c_id);
setSelectedCandidates(newSelected);
} else {
setSelectedCandidates([]);
}
};
const handleCandidateSelect = (candidateId) => {
const selectedIndex = selectedCandidates.indexOf(candidateId);
let newSelected = [];
if (selectedIndex === -1) {
  if (selectedCandidates.length < (parseInt(podState.podSize) - teamMembers.length)) {
    newSelected = [...selectedCandidates, candidateId];
  } else {
    showSnackbar('Maximum team size reached', 'warning');
    return;
  }
} else {
  newSelected = selectedCandidates.filter(id => id !== candidateId);
}

setSelectedCandidates(newSelected);
};
const handleAddTeamMembers = async () => {
setLoading(prev => ({ ...prev, addMembers: true }));
try {
const response = await axios.post(
process.env.REACT_APP_CLIENT_ADD_POD_MEMBERS,
{
podId: podid,
memberIds: selectedCandidates
},
{ headers: { Authorization: token } }
);
  if (response.status === 200) {
    showSnackbar('Team members added successfully', 'success');
    fetchPodDetails();
    setOpenTeamDialog(false);
    setSelectedCandidates([]);
  }
} catch (error) {
  console.error('Error adding team members:', error);
  showSnackbar('Failed to add team members', 'error');
} finally {
  setLoading(prev => ({ ...prev, addMembers: false }));
}
};

const handleEditRole = (member) => {
setSelectedMember(member);
setSelectedRole(member.role || '');
setSelectedPrimarySkill(member.primarySkill || '');
setSelectedSecondarySkill(member.secondarySkill || '');
setSelectedAvailability(member.availability || '');
setOpenRoleDialog(true);
};
const handleUpdateRole = async () => {
try {
const response = await axios.put(
process.env.REACT_APP_CLIENT_UPDATE_MEMBER_ROLE,
{
podId: podid,
memberId: selectedMember.c_id,
role: selectedRole,
primarySkill: selectedPrimarySkill,
secondarySkill: selectedSecondarySkill,
availability: selectedAvailability
},
{ headers: { Authorization: token } }
);
 if (response.status === 200) {
    showSnackbar('Member role updated successfully', 'success');
    fetchPodDetails();
    setOpenRoleDialog(false);
  }
} catch (error) {
  console.error('Error updating member role:', error);
  showSnackbar('Failed to update member role', 'error');
}
};
const handleViewProfile = (candidate) => {
  // Navigate to candidate profile view
  history.push('/podcandidatereview/' + candidate.c_id);
};
// Loading State
if (loading.page) {
return (
<Box sx={{
height: '100vh',
display: 'flex',
alignItems: 'center',
justifyContent: 'center',
flexDirection: 'column',
gap: 2
}}>
<CircularProgress size={40} />
<Typography variant="body1" color="textSecondary">
Loading pod details...
</Typography>
</Box>
);
}
// Error State
if (error.main) {
return (
<Box sx={{ p: 3 }}>
<Alert
severity="error"
action={
<Button 
           color="inherit" 
           size="small" 
           onClick={fetchPodDetails}
         >
Retry
</Button>
}
>
<AlertTitle>Error</AlertTitle>
{error.main}
</Alert>
</Box>
);
}





const handleEditMember = (member) => {
  setEditingMember(member);
  setEditedMemberData({
    Pod_Roles: member.Pod_Roles || '',
    time_zone: member.time_zone || '',
    region: member.region || '',
    Primary_Skill: member.Primary_Skill || '',
    Secondary_Skill: member.Secondary_Skill || '',
    Availability: member.Availability || ''
  });
};

const handleSaveMember = async (member) => {
  try {
    const payload = {
      podID: podid,
      candidateID: member.candidateID,
      id: member.id,
      ...editedMemberData
    };

    await axios.put(
      process.env.REACT_APP_CLIENT_PODCANDIDATEUPDATE,
      payload,
      { headers: { Authorization: token } }
    );

    fetchTeamMembers();
    setEditingMember(null);
    showSnackbar('Team member updated successfully', 'success');
  } catch (error) {
    console.error('Error updating team member:', error);
    showSnackbar('Failed to update team member', 'error');
  }
};

const handleRemoveMember = async (member) => {
  try {
    await axios.delete(
      process.env.REACT_APP_CLIENT_PODCANDIDATEDELETE,
      {
        headers: { Authorization: token },
        data: { 
          podID: podid, 
          candidateId: member.candidateID 
        }
      }
    );
    fetchTeamMembers();
    showSnackbar('Team member removed successfully', 'success');
  } catch (error) {
    console.error('Error removing team member:', error);
    showSnackbar('Failed to remove team member', 'error');
  }
};

// Render team members grid
const renderTeamMembers = () => (
  <Grid container spacing={3}>
    {teamMembers.length === 0 ? (
      // Empty state
      <Grid item xs={12}>
        <Box sx={{ 
          textAlign: 'center', 
          py: 8, 
          backgroundColor: 'rgba(0,0,0,0.02)',
          borderRadius: 2 
        }}>
          <PersonIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" color="textSecondary" gutterBottom>
            No Team Members Yet
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
            Visit the 'Find Talent' page to add team members to your pod
          </Typography>
        </Box>
      </Grid>
    ) : (
      // Team members list
      teamMembers.map((member) => (
        <Grid item xs={12} sm={6} md={4} key={member.candidateID}>
          <Card sx={{
            position: 'relative',
            transition: 'transform 0.2s ease',
            '&:hover': { transform: 'translateY(-4px)' }
          }}>
            <CardContent>
              {/* Member Actions */}
              <Box sx={{ position: 'absolute', right: 8, top: 8 }}>
                {editingMember?.candidateID === member.candidateID ? (
                  <IconButton onClick={() => handleSaveMember(member)}>
                    <SaveIcon />
                  </IconButton>
                ) : (
                  <>
                    <IconButton onClick={() => handleEditMember(member)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      color="error"
                      onClick={() => handleRemoveMember(member)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Box>

              {/* Member Info */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <Avatar
                  src={member.profilePicture ? 
                    `https://globldata.s3.amazonaws.com/${member.profilePicture}` : 
                    undefined
                  }
                  alt={member.Candidate_Name}
                >
                  <PersonIcon />
                </Avatar>
                <Box>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {member.Candidate_Name}
                  </Typography>
                  {editingMember?.candidateID === member.candidateID ? (
                    <Select
                    fullWidth
                    size="small"
                    value={editedMemberData.Pod_Roles || ''}
                    onChange={(e) => setEditedMemberData({
                      ...editedMemberData,
                      Pod_Roles: e.target.value
                    })}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <Typography color="text.secondary">Select Role</Typography>;
                      }
                      return selected;
                    }}
                  >
                    <MenuItem disabled value="">
                      <Typography color="text.secondary">Select Role</Typography>
                    </MenuItem>
                    {POD_ROLES.map(role => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      {member.Pod_Roles || 'Role not assigned'}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Divider sx={{ my: 2 }} />

              {/* Skills and Details */}
              <Stack spacing={2}>
                {editingMember?.candidateID === member.candidateID ? (
                  <>
   


{/* All other Select components will follow the same pattern */}
<Select
  fullWidth
  size="small"
  value={editedMemberData.Primary_Skill || ''}
  onChange={(e) => setEditedMemberData({
    ...editedMemberData,
    Primary_Skill: e.target.value
  })}
  displayEmpty
  renderValue={(selected) => {
    if (!selected) {
      return <Typography color="text.secondary">Select Primary Skill</Typography>;
    }
    return selected;
  }}
>
  <MenuItem disabled value="">
    <Typography color="text.secondary">Select Primary Skill</Typography>
  </MenuItem>
  {PRIMARY_SKILLS.map(skill => (
    <MenuItem key={skill} value={skill}>
      {skill}
    </MenuItem>
  ))}
</Select>

<Select
  fullWidth
  size="small"
  value={editedMemberData.Secondary_Skill || ''}
  onChange={(e) => setEditedMemberData({
    ...editedMemberData,
    Secondary_Skill: e.target.value
  })}
  displayEmpty
  renderValue={(selected) => {
    if (!selected) {
      return <Typography color="text.secondary">Select Secondary Skill</Typography>;
    }
    return selected;
  }}
>
  <MenuItem disabled value="">
    <Typography color="text.secondary">Select Secondary Skill</Typography>
  </MenuItem>
  {SECONDARY_SKILLS.map(skill => (
    <MenuItem key={skill} value={skill}>
      {skill}
    </MenuItem>
  ))}
</Select>

<Select
  fullWidth
  size="small"
  value={editedMemberData.region || ''}
  onChange={(e) => setEditedMemberData({
    ...editedMemberData,
    region: e.target.value
  })}
  displayEmpty
  renderValue={(selected) => {
    if (!selected) {
      return <Typography color="text.secondary">Select Region</Typography>;
    }
    return selected;
  }}
>
  <MenuItem disabled value="">
    <Typography color="text.secondary">Select Region</Typography>
  </MenuItem>
  {REGIONS.map(region => (
    <MenuItem key={region} value={region}>
      {region}
    </MenuItem>
  ))}
</Select>

<Select
  fullWidth
  size="small"
  value={editedMemberData.time_zone || ''}
  onChange={(e) => setEditedMemberData({
    ...editedMemberData,
    time_zone: e.target.value
  })}
  displayEmpty
  renderValue={(selected) => {
    if (!selected) {
      return <Typography color="text.secondary">Select Timezone</Typography>;
    }
    return selected;
  }}
>
  <MenuItem disabled value="">
    <Typography color="text.secondary">Select Timezone</Typography>
  </MenuItem>
  {TIMEZONES.map(timezone => (
    <MenuItem key={timezone} value={timezone}>
      {timezone}
    </MenuItem>
  ))}
</Select>

<Select
  fullWidth
  size="small"
  value={editedMemberData.Availability || ''}
  onChange={(e) => setEditedMemberData({
    ...editedMemberData,
    Availability: e.target.value
  })}
  displayEmpty
  renderValue={(selected) => {
    if (!selected) {
      return <Typography color="text.secondary">Select Availability</Typography>;
    }
    return selected;
  }}
>
  <MenuItem disabled value="">
    <Typography color="text.secondary">Select Availability</Typography>
  </MenuItem>
  {AVAILABILITY_OPTIONS.map(option => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ))}
</Select>
                  </>
                ) : (
                  <>
                    <Box>
                      <Typography variant="caption" color="textSecondary">Skills</Typography>
                      <Stack direction="row" spacing={1} mt={0.5}>
                        {member.Primary_Skill && (
                          <Chip label={member.Primary_Skill} size="small" />
                        )}
                        {member.Secondary_Skill && (
                          <Chip label={member.Secondary_Skill} size="small" />
                        )}
                      </Stack>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="textSecondary">Region</Typography>
                      <Typography variant="body2">{member.region || 'Not specified'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="textSecondary">Timezone</Typography>
                      <Typography variant="body2">{member.time_zone || 'Not specified'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="textSecondary">Availability</Typography>
                      <Typography variant="body2">{member.Availability || 'Not specified'}</Typography>
                    </Box>
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))
    )}
  </Grid>
);
// Main Component Return
return (
  <PageContainer>
    {/* 1. Breadcrumbs Navigation */}
    <StyledBreadcrumbs>
      <Link to="/FindPodsDetails">
        <Typography color="primary" sx={{ 
          fontSize: 18, 
          fontWeight: 600, 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1 
        }}>
          <ArrowBackIcon fontSize="small" />
          View Pod
        </Typography>
      </Link>
      <Typography color="textSecondary" fontSize={18} fontWeight={600}>
        Manage Pod
      </Typography>
    </StyledBreadcrumbs>

    {/* 2. Pod Details Card */}
    <MainCard>
      <CardContent>
      {renderError('main')}
        {/* Pod Header */}
        <Box sx={{ textAlign: 'center', mb: 4, position: 'relative' }}>
          <Typography variant="h4" fontWeight={600} color="primary" sx={{ mb: 2 }}>
            Edit Pod
          </Typography>
          <StatusChip
            label={podState.status.toUpperCase()}
            status={podState.status}
            icon={getStatusIcon(podState.status)}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          />
        </Box>
        {/* {renderImageUpload()} */}
        {/* Replace the existing PodImageUpload section */}
       
{/* Pod Image Upload */}
<ImageUploadComponent
  initialImage={podState.picture}
  onImageChange={(imageKey) => {
    setPodState(prev => ({
      ...prev,
      picture: imageKey
    }));
  }}
  config={s3Config}
  defaultImage={defaultimg}
/>

        {/* Pod Image Upload */}
        {/* <PodImageUpload>
          <StyledAvatar
            src={imageState.imageUrl || defaultimg}
            alt={podState.podName}
          />
          <UploadButton component="label" disabled={loading.upload}>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
            {loading.upload ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <CloudUploadIcon />
            )}
          </UploadButton>
        </PodImageUpload> */}

        {/* 3. Form Sections */}
        {/* Basic Information */}
        <FormSection>
            <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
              Basic Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Pod Name"
                  value={podState.podName}
                  onChange={handleInputChange('podName')}
                  placeholder="Enter Pod Name"
                  error={Boolean(errors.main && errors.main.includes('name'))}
                  helperText={errors.main && errors.main.includes('name') ? errors.main : ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledSelect fullWidth>
                  <InputLabel>Pod Type</InputLabel>
                  <Select
                    value={podState.podtype}
                    onChange={handleInputChange('podtype')}
                    label="Pod Type"
                    error={Boolean(errors.main && errors.main.includes('type'))}
                  >
                    {POD_TYPES.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledSelect>
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Pod Description"
                  value={podState.podDescription}
                  onChange={handleInputChange('podDescription')}
                  placeholder="Enter Pod Description"
                  error={Boolean(errors.main && errors.main.includes('description'))}
                  helperText={errors.main && errors.main.includes('description') ? errors.main : ''}
                />
              </Grid>
            </Grid>
          </FormSection>

          {/* Technical Details Section with Pod Size Validation */}
          <FormSection>
            <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
              Technical Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StyledSelect fullWidth>
                  <InputLabel>Tech Stack</InputLabel>
                  <Select
                    value={podState.techStack}
                    onChange={handleInputChange('techStack')}
                    label="Tech Stack"
                    error={Boolean(errors.main && errors.main.includes('tech stack'))}
                  >
                    {TECH_STACKS.map(stack => (
                      <MenuItem key={stack.value} value={stack.value}>
                        {stack.label}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledSelect fullWidth>
                  <InputLabel>Pod Size</InputLabel>
                  <Select
                    value={podState.podSize}
                    onChange={handleInputChange('podSize')}
                    label="Pod Size"
                    error={Boolean(errors.main && errors.main.includes('size'))}
                  >
                    {POD_SIZES.map(size => (
                      <MenuItem key={size.value} value={size.value}>
                        {size.label}
                        {teamMembers.length > 0 && (
                          <Typography component="span" color="text.secondary" sx={{ ml: 1 }}>
                            ({teamMembers.length}/{size.value})
                          </Typography>
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledSelect>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                  Current team size: {teamMembers.length} members
                </Typography>
              </Grid>
            </Grid>
          </FormSection>

          {/* Communication Details Section */}
          <FormSection>
            <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
              Communication Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StyledSelect fullWidth>
                  <InputLabel>Primary Language</InputLabel>
                  <Select
                    value={podState.primaryLanguage}
                    onChange={handleInputChange('primaryLanguage')}
                    label="Primary Language"
                  >
                    {PRIMARY_LANGUAGES.map(language => (
                      <MenuItem key={language} value={language}>
                        {language}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledSelect fullWidth>
                  <InputLabel>Pod Timezone</InputLabel>
                  <Select
                    value={podState.timezone}
                    onChange={handleInputChange('timezone')}
                    label="Pod Timezone"
                  >
                    {TIMEZONES.map(timezone => (
                      <MenuItem key={timezone} value={timezone}>
                        {timezone}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledSelect>
              </Grid>
            </Grid>
          </FormSection>
       


        {/* 4. Team Members Section */}
        <MainCard sx={{ mt: 4 }}>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3 
          }}>
            <Typography variant="h5" fontWeight={600}>
              Team Members ({teamMembers.length}/{podState.podSize})
            </Typography>
          </Box>

          {renderTeamMembers()}
        </CardContent>
      </MainCard>

        {/* 5. Action Buttons */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          gap: 2,
          mt: 4 
        }}>
          <ActionButton
            variant="outlined"
            onClick={() => history.goBack()}
            startIcon={<CloseIcon />}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSave}
            disabled={loading.save}
            startIcon={loading.save ? 
              <CircularProgress size={20} /> : 
              <SaveIcon />
            }
          >
            Save Changes
          </ActionButton>
        </Box>
      </CardContent>
    </MainCard>

    {/* 6. Dialogs */}
    <AddTeamMembersDialog />
    <EditRoleDialog />
    <ConfirmationDialog />

    {/* 7. Notifications */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
    >
      <Alert 
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  </PageContainer>
);
}
export default ClientManagePod;