import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Chip,
  CircularProgress,
  Fade,
  Collapse,
  Divider,
  useTheme,
  useMediaQuery
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import axios from "axios";
import { NavHashLink } from 'react-router-hash-link';
import { MdAssignment, MdExpandMore, MdExpandLess } from 'react-icons/md';
import { FaTasks, FaClock, FaUsers } from 'react-icons/fa';
import { useAuthToken } from "../TokenContext";
import { FormattedMessage, useIntl } from 'react-intl';

const PageWrapper = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, #f6f9fc 0%, #ecf3f8 100%)`,
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  margin: 'auto',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  maxWidth: '1200px',
  margin: '0 auto',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const PodCard = styled(Card)(({ theme }) => ({
  margin: 'auto',
  width:'30vw',
  borderRadius: theme.spacing(2),
  background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
  boxShadow: '0 4px 20px rgba(0, 180, 216, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0, 180, 216, 0.15)',
  }
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: "auto",
  minHeight: '80vh',
  gap: theme.spacing(2),
  '& .MuiCircularProgress-root': {
    color: '#00B4D8',
  }
}));

const PodHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'rgba(0, 180, 216, 0.02)',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  }
}));

const StatusBadge = styled(Chip)(({ theme, status }) => ({
  borderRadius: theme.spacing(2),
  fontWeight: 600,
  backgroundColor: status === 'Active' ? '#e3fcef' : '#ffedef',
  color: status === 'Active' ? '#00a854' : '#ff4d4f',
}));

const ActionButton = styled(NavHashLink)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#00B4D8',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  boxShadow: '0 4px 12px rgba(0, 180, 216, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#00B4D8',
    color: '#ffffff',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 16px rgba(0, 180, 216, 0.2)',
  },
  '&.Mui-disabled': {
    backgroundColor: '#f5f5f5',
    color: '#bdbdbd',
    pointerEvents: 'none',
  }
}));

const ActionSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0, 180, 216, 0.03)',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    padding: theme.spacing(3),
    gap: theme.spacing(4),
  }
}));

const ActionGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const PodIcon = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 180, 216, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  '& svg': {
    color: '#00B4D8',
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    '& svg': {
      fontSize: '1.5rem',
    }
  }
}));

const PodTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  }
}));

const Podstalent = () => {
  const intl = useIntl();
  const token = useAuthToken();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector(state => state.auth);
  const [podDetails, setPodDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedPod, setExpandedPod] = useState(null);
 
  useEffect(() => {
    const fetchPodDetails = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_TALENT_PODLISTTALENT,
          { candidateID: user.data[0].c_id },
          { headers: { Authorization: token, 'Content-Type': 'application/json' } }
        );
        setPodDetails(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error("Error:", err);
        setLoading(false);
      }
    };

    fetchPodDetails();
  }, [token, user]);

  const handlePodClick = (podId) => {
    setExpandedPod(expandedPod === podId ? null : podId);
  };

  if (loading) {
    return (
      <Fade in={true} timeout={300}>
        <LoadingContainer>
          <CircularProgress size={isMobile ? 40 : 60} />
          <Typography variant="h6" color="textSecondary">
            Loading Pods...
          </Typography>
        </LoadingContainer>
      </Fade>
    );
  }

  if (!loading && (!podDetails || podDetails.length === 0)) {
    return (
      <Fade in={true} timeout={300}>
        <LoadingContainer>
          <Typography variant="h6" color="textSecondary">
            No pods found
          </Typography>
        </LoadingContainer>
      </Fade>
    );
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        <Box textAlign="center" mb={isMobile ? 3 : 6}>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            fontWeight={700} 
            color="#2c3e50" 
            gutterBottom
          >
            <FormattedMessage id="yourPods" defaultMessage="Your Pods" />
          </Typography>
          <Typography 
            variant={isMobile ? "body2" : "subtitle1"} 
            color="text.secondary"
          >
            <FormattedMessage id="managePods" defaultMessage="Manage all your pods and their activities" />
          </Typography>
        </Box>
  
        {podDetails.map((pod) => (
          <Fade in={true} key={pod.podID}>
            <PodCard>
              <PodHeader onClick={() => handlePodClick(pod.podID)}>
                <Box display="flex" alignItems="center">
                  <PodIcon>
                    <FaUsers />
                  </PodIcon>
                  <Box>
                    <PodTitle variant="h6" fontWeight={600}>
                      {pod.podName}
                    </PodTitle>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <StatusBadge 
                    label={pod.status} 
                    status={pod.status}
                    size={isMobile ? "small" : "medium"}
                  />
                  {expandedPod === pod.podID ? 
                    <MdExpandLess size={isMobile ? 20 : 24} /> : 
                    <MdExpandMore size={isMobile ? 20 : 24} />
                  }
                </Box>
              </PodHeader>
  
              <Collapse in={expandedPod === pod.podID}>
                <Divider />
                <ActionSection>
                  <ActionGroup>
                    <Tooltip title={
                      pod.status === 'Active' 
                        ? intl.formatMessage({ id: 'podActivity' }) 
                        : intl.formatMessage({ id: 'inactivePod' })
                    } arrow>
                      <span>
                        <ActionButton
                          disabled={pod.status !== 'Active'}
                          to={{
                            pathname: '/Podactivitytalent',
                            state: { podid: pod.podID, clientid: pod.clientID }
                          }}
                        >
                          <MdAssignment />
                        </ActionButton>
                      </span>
                    </Tooltip>
                    <Typography variant="caption" color="text.secondary">
                      <FormattedMessage id="activity" defaultMessage="Activity" />
                    </Typography>
                  </ActionGroup>
  
                  <ActionGroup>
                    <Tooltip title={
                      pod.status === 'Active' 
                        ? intl.formatMessage({ id: 'podRetro' }) 
                        : intl.formatMessage({ id: 'inactivePod' })
                    } arrow>
                      <span>
                        <ActionButton
                          disabled={pod.status !== 'Active'}
                          to={{
                            pathname: '/PodactivitytalentRetro',
                            state: { podid: pod.podID }
                          }}
                        >
                          <MdAssignment />
                        </ActionButton>
                      </span>
                    </Tooltip>
                    <Typography variant="caption" color="text.secondary">
                      <FormattedMessage id="retro" defaultMessage="Retro" />
                    </Typography>
                  </ActionGroup>
  
                  <ActionGroup>
                    <Tooltip title={
                      pod.status === 'Active' 
                        ? intl.formatMessage({ id: 'podTasks' }) 
                        : intl.formatMessage({ id: 'inactivePod' })
                    } arrow>
                      <span>
                        <ActionButton
                          disabled={pod.status !== 'Active'}
                          to={{
                            pathname: '/Createtasktalent',
                            state: { podname: pod.podName, podid: pod.podID }
                          }}
                        >
                          <FaTasks />
                        </ActionButton>
                      </span>
                    </Tooltip>
                    <Typography variant="caption" color="text.secondary">
                      <FormattedMessage id="tasks" defaultMessage="Tasks" />
                    </Typography>
                  </ActionGroup>
  
                  <ActionGroup>
                    <Tooltip title={
                      pod.status === 'Active' 
                        ? intl.formatMessage({ id: 'timeSubmit' }) 
                        : intl.formatMessage({ id: 'inactivePod' })
                    } arrow>
                      <span>
                        <ActionButton
                          disabled={pod.status !== 'Active'}
                          to={{
                            pathname: '/TimeSubmit',
                            state: { podName: pod.podName, podid: pod.podID }
                          }}
                        >
                          <FaClock />
                        </ActionButton>
                      </span>
                    </Tooltip>
                    <Typography variant="caption" color="text.secondary">
                      <FormattedMessage id="time" defaultMessage="Time" />
                    </Typography>
                  </ActionGroup>
                </ActionSection>
              </Collapse>
            </PodCard>
          </Fade>
        ))}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Podstalent;