import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const Bitbucketcodebydaywisetalent = ({initialPodIds}) => {
  const [data, setData] = useState(null);
  const [selectedCandidateId, setSelectedCandidateId] = useState("");
  const [talentUsers, setTalentUsers] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    fetchData();
  }, [initialPodIds, token]);

  const fetchData = async () => {
    if (!token) return;
  
    const requestBody = {
      talent_id: user.data[0].c_id,
      pod_id: initialPodIds || [],
    };
  
    try {
      const response = await fetch(
        //REACT_APP_TALENT_BITBUCKETCODEBYDAYTALENT
        process.env.REACT_APP_TALENT_CODEBYDAYTALENT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token,
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to fetch data. Status: ' + response.status);
      }
  
      const responseData = await response.json();
      setData(responseData.body);
      setTalentUsers(responseData.body?.talent_users || []);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };
  

  const formatDataForChart = (data, selectedCandidateId) => {
    if (!data || !data.talent_users || !data.talent_users.length) {
      return {
        labels: [],
        datasets: [],
      };
    }
  
    if (selectedCandidateId) {
      const userData = data.talent_users.find(user => user.talent_user === selectedCandidateId);
      if (!userData) {
        return {
          labels: [],
          datasets: [],
        };
      }
  
      const labels = userData.stats.map(item => item.weekday);
      const checkInsData = userData.stats.map(item => item.checkins_count);
      const linesAddedData = userData.stats.map(item => item.lines_added_count);
  
      return {
        labels: labels,
        datasets: [
          {
            label: 'Check-ins',
            data: checkInsData,
            backgroundColor: 'yellow',
            borderWidth: 1,
          },
          {
            label: 'Lines Added',
            data: linesAddedData,
            backgroundColor: 'black',
            borderWidth: 1,
          },
        ],
      };
    } else {
      const labels = data.total_repo_stats.map(item => item.weekday);
      const checkInsData = data.total_repo_stats.map(item => item.checkins_count);
      const linesAddedData = data.total_repo_stats.map(item => item.lines_added_count);
  
      return {
        labels: labels,
        datasets: [
          {
            label: 'Check-ins',
            data: checkInsData,
            backgroundColor: 'yellow',
            borderWidth: 1,
          },
          {
            label: 'Lines Added',
            data: linesAddedData,
            backgroundColor: 'black',
            borderWidth: 1,
          },
        ],
      };
    }
  };
  
  

  const chartData = formatDataForChart(data, selectedCandidateId);

  return (
    <div className="chart-container">
      <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
          height: '30rem',
          width: '20rem',
        }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'linesOfCodeByDay' })}</h2>
        <div>
          <select
            style={{ width: '12rem' }}
            value={selectedCandidateId}
            onChange={(event) => setSelectedCandidateId(event.target.value)}
          >
            <option value="">{intl.formatMessage({ id: 'selectAUser' })}</option>
            {talentUsers.map(user => (
              <option key={user.talent_user} value={user.talent_user}>
                {user.talent_user}
              </option>
            ))}
          </select>
        </div>

        {chartData.datasets.length ? (
          <div style={{ height: '25rem', width: '20rem' }}>
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: { stacked: true },
                  y: { stacked: true },
                },
              }}
            />
          </div>
        ) : (
          <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
        )}
      </div>
    </div>
  );
};

export default Bitbucketcodebydaywisetalent;
