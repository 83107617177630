import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  Fade,
  Divider,
  Paper,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

import { styled } from '@mui/material/styles';

// Styled Components (keeping the same styling)
const PageWrapper = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, #f6f9fc 0%, #ecf3f8 100%)`,
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  margin: 'auto',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  maxWidth: '1200px',
  margin: '0 auto',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const PodCard = styled(Card)(({ theme }) => ({
  margin: '20px auto',
  width: '100%',
  maxWidth: '800px',
  borderRadius: theme.spacing(2),
  background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
  boxShadow: '0 4px 20px rgba(0, 180, 216, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0, 180, 216, 0.15)',
  }
}));

const FormSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const TableSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'rgba(0, 180, 216, 0.03)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(135deg, #00B4D8 0%, #0096c7 100%)',
  color: '#ffffff',
  '&:hover': {
    background: 'linear-gradient(135deg, #0096c7 0%, #00B4D8 100%)',
  }
}));

const PodRetroManager = () => {
  const token = useAuthToken();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector(state => state.auth);

  // State declarations
  const [loading, setLoading] = useState(true);
  const [podDetails, setPodDetails] = useState([]);
  const [selectedPodId, setSelectedPodId] = useState('');
  const [retrolisting, setRetrolisting] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState('');
  const [sprintData, setSprintData] = useState([]);
  const [currentPageretro, setCurrentPageretro] = useState(1);
  const [teamFilter, setTeamFilter] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const itemsPerPageretro = 4;

  // Initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const headers = {
          'Authorization': token,
          'Content-Type': 'application/json',
        };

        // Fetch pod details
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_GETCLIENTPOD,
          { clientID: user.data[0].emp_id },
          { headers }
        );

        if (response.data?.data) {
          setPodDetails(response.data.data.podDetails || []);
        }

        // Fetch sprint data
        const authResponse = await axios.post(
          process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
          { Organizational_Code: user.data[0].client_code },
          { headers }
        );

        if (authResponse.data?.body?.data?.[0]) {
          const authData = authResponse.data.body.data[0];
          const sprintResponse = await axios.post(
            process.env.REACT_APP_CLIENT_FETCHSPRINTNUMBERS,
            {
              Jira_URL: authData.Jira_URL,
              Jira_admin_login_email_address: authData.Jira_admin_login_email_address,
              Jira_admin_API_Token: authData.Jira_admin_API_Token
            },
            { headers }
          );

          if (sprintResponse.data?.body) {
            const parsedData = JSON.parse(sprintResponse.data.body);
            setSprintData(parsedData.sprints || []);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [token, user]);

  // Fetch retro listing when pod is selected
  useEffect(() => {
    if (selectedPodId) {
      fetchRetroListing(selectedPodId);
    }
  }, [selectedPodId]);

  const getRetroFields = (retroString) => {
    try {
      return typeof retroString === 'string' ? JSON.parse(retroString) : retroString;
    } catch (error) {
      console.error('Error parsing retro:', error);
      return {};
    }
  };

  const fetchRetroListing = async (podId) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_PODRETROLIST,
        { pods_id: podId },
        { 
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data?.data) {
        const processedData = response.data.data.map(item => ({
          ...item,
          retroFields: getRetroFields(item.retro)
        }));
        setRetrolisting(processedData);
      }
    } catch (error) {
      console.error('Error fetching retro listing:', error);
    }
  };

  const handleSubmitpodretro = async (e) => {
    e.preventDefault();

    const retroFeedback = {
      start_doing: e.target.start_doing.value,
      stop_doing: e.target.stop_doing.value,
      keep_doing: e.target.keep_doing.value,
    };

    const formData = {
      pod_feedback: e.target.podRetro.value,
      retro: retroFeedback,
      sprint: selectedSprint,
      status: "Completed",
      submitted_by: user.data[0].emp_firstname || null,
      submitted_by_id: user.data[0].emp_id,
      pods_id: selectedPodId
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODRETROS,
        formData,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 200) {
        alert("Posted Successfully.");
        fetchRetroListing(selectedPodId);
        e.target.reset();
      } else {
        alert(response.data.data || "Error submitting retro");
      }
    } catch (error) {
      console.error('Error submitting retro:', error);
      alert("Error submitting retro. Please try again.");
    }
  };

  const sortedAndFilteredRetros = useMemo(() => {
    let filtered = [...retrolisting];
    
    if (teamFilter) {
      filtered = filtered.filter(item => item.submitted_by === teamFilter);
    }

    return filtered.sort((a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }, [retrolisting, teamFilter, sortDirection]);

  const startIndex = (currentPageretro - 1) * itemsPerPageretro;
  const endIndex = Math.min(startIndex + itemsPerPageretro, sortedAndFilteredRetros.length);
  const currentRetros = sortedAndFilteredRetros.slice(startIndex, endIndex);
  const totalPages = Math.ceil(sortedAndFilteredRetros.length / itemsPerPageretro);

  if (loading) {
    return (
      <Fade in={true} timeout={300}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <CircularProgress size={60} />
        </Box>
      </Fade>
    );
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        <Box textAlign="center" mb={isMobile ? 3 : 6}>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            fontWeight={700} 
            color="#2c3e50" 
            gutterBottom
          >
            {intl.formatMessage({ id: 'podRetro' })}
          </Typography>
        </Box>

        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'selectPod' })}</InputLabel>
            <Select
              value={selectedPodId}
              onChange={(e) => setSelectedPodId(e.target.value)}
              label={intl.formatMessage({ id: 'selectPod' })}
            >
              {podDetails.map((pod) => (
                <MenuItem key={pod.id} value={pod.id}>
                  {pod.podName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {selectedPodId && (
          <PodCard>
            <FormSection>
              <form onSubmit={handleSubmitpodretro}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={4}
                      name="podRetro"
                      label={intl.formatMessage({ id: 'podRetro' })}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel>{intl.formatMessage({ id: 'sprint' })}</InputLabel>
                      <Select
                        value={selectedSprint}
                        onChange={(e) => setSelectedSprint(e.target.value)}
                        label={intl.formatMessage({ id: 'sprint' })}
                      >
                        {sprintData.map((sprint, index) => (
                          <MenuItem key={index} value={sprint.sprint_name}>
                            {sprint.sprint_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={3}
                      name="start_doing"
                      label={intl.formatMessage({ id: 'startDoing' })}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={3}
                      name="stop_doing"
                      label={intl.formatMessage({ id: 'stopDoing' })}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={3}
                      name="keep_doing"
                      label={intl.formatMessage({ id: 'keepDoing' })}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StyledButton
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                    >
                      {intl.formatMessage({ id: 'post' })}
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            </FormSection>

            <Divider />

            <TableSection>
              <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">
                  {intl.formatMessage({ id: 'retroHistory' })}
                </Typography>
                <FormControl size="small" sx={{ minWidth: 200 }}>
                  <InputLabel>
                    {intl.formatMessage({ id: 'filterByMember' })}
                  </InputLabel>
                  <Select
                    value={teamFilter}
                    onChange={(e) => setTeamFilter(e.target.value)}
                    label={intl.formatMessage({ id: 'filterByMember' })}
                  >
                    <MenuItem value="">
                      {intl.formatMessage({ id: 'allMembers' })}
                    </MenuItem>
                    {Array.from(new Set(retrolisting.map(item => item.submitted_by)))
                      .map((member, index) => (
                        <MenuItem key={index} value={member}>{member}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{intl.formatMessage({ id: 'member' })}</TableCell>
                      <TableCell 
                        onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
                        style={{ cursor: 'pointer' }}
                      >
                        {intl.formatMessage({ id: 'date' })}
                        {sortDirection === 'asc' ? ' ↑' : ' ↓'}
                      </TableCell>
                      <TableCell>{intl.formatMessage({ id: 'sprint' })}</TableCell>
                      <TableCell>{intl.formatMessage({ id: 'feedback' })}</TableCell>
                      <TableCell>{intl.formatMessage({ id: 'startDoing' })}</TableCell>
                      <TableCell>{intl.formatMessage({ id: 'stopDoing' })}</TableCell><TableCell>{intl.formatMessage({ id: 'keepDoing' })}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentRetros.length > 0 ? (
                      currentRetros.map((retro, index) => (
                        <TableRow key={index}>
                          <TableCell>{retro.submitted_by}</TableCell>
                          <TableCell>
                            {moment(retro.createdAt).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell>{retro.sprint}</TableCell>
                          <TableCell>{retro.pod_feedback}</TableCell>
                          <TableCell>{retro.retroFields.start_doing}</TableCell>
                          <TableCell>{retro.retroFields.stop_doing}</TableCell>
                          <TableCell>{retro.retroFields.keep_doing}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Typography variant="body2" color="textSecondary">
                            {intl.formatMessage({ id: 'noRetrosFound' })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <Box p={2}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                      <Button
                        onClick={() => setCurrentPageretro(prev => Math.max(1, prev - 1))}
                        disabled={currentPageretro === 1}
                        variant="outlined"
                        size="small"
                      >
                        {intl.formatMessage({ id: 'previous' })}
                      </Button>
                    </Grid>
                    {[...Array(totalPages)].map((_, index) => (
                      <Grid item key={index}>
                        <Button
                          variant={currentPageretro === index + 1 ? "contained" : "outlined"}
                          size="small"
                          onClick={() => setCurrentPageretro(index + 1)}
                        >
                          {index + 1}
                        </Button>
                      </Grid>
                    ))}
                    <Grid item>
                      <Button
                        onClick={() => setCurrentPageretro(prev => Math.min(totalPages, prev + 1))}
                        disabled={currentPageretro === totalPages}
                        variant="outlined"
                        size="small"
                      >
                        {intl.formatMessage({ id: 'next' })}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </TableContainer>
            </TableSection>
          </PodCard>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default PodRetroManager;