import React, { useState, useEffect,memo ,useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useAuthToken } from '../TokenContext';
import AWS from 'aws-sdk';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { updateProfilecandidate } from './action/auth';
import { login } from './action/auth';
import S3FileUpload from 'react-s3';
import PersonIcon from '@mui/icons-material/Person';

// MUI Components


// MUI Components
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Box,
  Avatar,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Checkbox,
  FormControlLabel,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment
} from '@mui/material';

// MUI Icons
import {
  CloudUpload as CloudUploadIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  PhotoCamera as PhotoCameraIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  Save as SaveIcon,
  LinkedIn as LinkedInIcon,
  Language as LanguageIcon,
  AttachMoney as AttachMoneyIcon
} from '@mui/icons-material';

// Rest of your code remains the same...
// Styled components for custom styling
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Constants for dropdown options
const COUNTRY_OPTIONS = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'india', label: 'India' }
];

const WORK_AUTHORIZATION = [
  { value: 'US Citizen', label: 'US Citizen' },
  { value: 'Permanent Resident', label: 'Permanent Resident' },
  { value: 'EAD', label: 'EAD' },
  { value: 'OPT-EAD', label: 'OPT-EAD' },
  { value: 'H4-EAD', label: 'H4-EAD' },
  { value: 'H1B', label: 'H1B' },
  { value: 'TN', label: 'TN' },
  { value: 'Not Applicable (NA)', label: 'Not Applicable (NA)' }
];

const TECH_STACK = [
  { value: 'MEAN', label: 'MEAN' },
  { value: 'MERN', label: 'MERN' },
  { value: 'LAMP', label: 'LAMP' },
  { value: 'MEVN', label: 'MEVN' }
];

const INDUSTRY_OPTIONS = [
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'IT & Software', label: 'IT & Software' },
  { value: 'Banking and Financial', label: 'Banking and Financial' },
  { value: 'Services', label: 'Services' }
];

const JOB_TITLE_OPTIONS = [
  { value: 'UI/UX Designer', label: 'UI/UX Designer' },
  { value: 'Software Engineer', label: 'Software Engineer' },
  { value: 'Full-Stack Developer', label: 'Full-Stack Developer' },
  // ... other job titles
];

// AWS Configuration
const awsConfig = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};
console.log("aws",awsConfig)
const Createyourprofile = () => {
  // Hooks
  
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const [uploading, setUploading] = useState(false);
  // State management
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [openDialog, setOpenDialog] = useState(false);

  // Form data state
  const [formData, setFormData] = useState({
    basicInfo: {
      firstname: '',
      middlename: '',
      lastname: '',
      profilePicture: '',
      about: '',
      country: '',
      work_authorization: ''
    },
    skills: {
      primaryskill: '',
      secondaryskill: '',
      techStack: '',
      industry: '',
      job_title: ''
    },
    education: [],
    certifications: [],
    experience: [],
    portfolio: [],
    otherInfo: {
      linkedin: '',
      github: '',
      portfolioUrl: '',
      calendlyUrl: '',
      timezone: '',
      availability: '',
     
      ratePerHour: '',
      ratePerMonth: '',
      currency: ''
    }
  });

  // AWS S3 instance
  const s3 = new AWS.S3(awsConfig);

  // Steps configuration
  const steps = [
    'Basic Information',
    'Skills & Expertise',
    'Education',
    'Certifications',
    'Work Experience',
    'Portfolio',
    'Other Information'
  ];
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  
  
  

  // Handle form field changes
// In CreateProfile component
const handleInputChange = (section, field, value) => {
  setFormData(prev => ({
    ...prev,
    [section]: {
      ...prev[section],
      [field]: value
    }
  }));
};
  // Handle array field changes (education, experience, certifications)
  const handleArrayFieldChange = (section, index, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: prev[section].map((item, i) => 
        i === index 
          ? { ...item, [field]: value }
          : item
      )
    }));
  };

  // Add debounced save function if needed
  const debouncedSave = React.useCallback(
    debounce((newFormData) => {
      // Optionally save to localStorage or perform other operations
      localStorage.setItem('formData', JSON.stringify(newFormData));
    }, 500),
    []
  );
  useEffect(() => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    debouncedSave(formData);
  }, [formData, debouncedSave]);


 
  // Remove item from array fields
  const handleRemoveItem = (section, index) => {
    setFormData(prev => ({
      ...prev,
      [section]: prev[section].filter((_, i) => i !== index)
    }));
  };

  // Handle step navigation
  const handleNext = () => {
    setActiveStep(prev => prev + 1);
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  // Form submission
  // Add these functions in the CreateProfile component

const handlePortfolioSubmit = async (portfolioData) => {
  try {
    const portfolioUserId = user.data[0].type === 'Provider' 
      ? user.data[0].id 
      : user.data[0].c_id;

    // Process and upload portfolio images
    const portfolioItemsWithUrls = await Promise.all(
      portfolioData.map(async (item) => {
        const imageUrls = item.images 
          ? await Promise.all(item.images.map(file => handleFileUpload(file, 'portfolio')))
          : [];

        return {
          portfolio_user_id: portfolioUserId,
          project_objective: item.projectObjective,
          project_description: item.projectDescription,
          industry: item.industry,
          project_files: imageUrls,
        };
      })
    );

    // Send to API
    const response = await fetch(
      process.env.REACT_APP_TALENT_CREATEPORTFOLIO,
      {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(portfolioItemsWithUrls),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to create portfolio');
    }

    const data = await response.json();
    if (data.statusCode === 400 && data.body === 'Already 5 row values exist for this portfolio_user_id.') {
      setAlertMessage({
        type: 'warning',
        message: 'Maximum of 5 portfolio items allowed.'
      });
    } else {
      setAlertMessage({
        type: 'success',
        message: 'Portfolio updated successfully!'
      });
    }
  } catch (error) {
    console.error('Error creating portfolio:', error);
    setAlertMessage({
      type: 'error',
      message: 'Failed to create portfolio. Please try again.'
    });
  }
};

// Modify the main form submission to include portfolio
// Modified handleSubmit function to match previous implementation
const handleSubmit = async (event) => {
  event.preventDefault();
  setIsLoading(true);

  try {
    // Prepare the main profile payload structured like the previous implementation
    const payload = {
      id: user.data[0].c_id,
      firstname: formData.basicInfo.firstname,
      middlename: formData.basicInfo.middlename,
      lastname: formData.basicInfo.lastname,
      candidateemail: user.data[0].email,
      area: formData.skills.primaryskill,
      github_repo_name: formData.otherInfo.github_repo_name,
      github_username: formData.otherInfo.github_username,
      jira_username: formData.otherInfo.jira_username,
      sonarqube_project_key: formData.otherInfo.sonarqube_project_key,
      country: formData.basicInfo.country,
      work_authorization: formData.basicInfo.work_authorization,
      primaryskill: formData.skills.primaryskill,
      secondaryskill: formData.skills.secondaryskill,
      portfolio: formData.otherInfo.portfolioUrl,
      linkedin: formData.otherInfo.linkedin,
      github: formData.otherInfo.github,
      industry: formData.skills.industry,
      job_title: formData.skills.job_title,
      techStack: formData.skills.techStack,
      about: formData.basicInfo.about,
      ratePerhour: formData.otherInfo.ratePerHour,
      ratePermonth: formData.otherInfo.ratePerMonth,
      currency: formData.otherInfo.currency,
      workhours: formData.otherInfo.workhours,
      resume: formData.basicInfo.resume,
      coding_assessment_url: formData.basicInfo.coding_assessment_url,
      timezone: formData.otherInfo.timezone,
      availability: formData.otherInfo.availability,
      hoursperweek: formData.otherInfo.hoursperweek,
      currentStatus: formData.otherInfo.currentStatus,
      availabledate: formData.otherInfo.availabledate,
      profilePicture: formData.basicInfo.profilePicture,
      calendlyurl: formData.otherInfo.calendlyUrl,
      talent_code: formData.otherInfo.talent_code
    };

    // Update main profile
    await dispatch(updateProfilecandidate(payload, {
      Authorization: token,
      'Content-Type': 'application/json'
    }));

    // Format education data
    const formattedEducation = formData.education.map(edu => ({
      degree: edu.degree,
      domain: edu.domain,
      institution: edu.institution,
      startDate: edu.startDate,
      endDate: edu.endDate,
      candidateID: user.data[0].c_id
    }));

    // Format experience data
    const formattedExp = formData.experience.map(exp => ({
      work_organization: exp.work_organization,
      work_position: exp.work_position,
      workStartdate: exp.workStartdate,
      workEnddate: exp.workEnddate,
      present: exp.present ? 1 : 0,
      candidateID: user.data[0].c_id
    }));

    // Format certification data
    const formattedCertifications = formData.certifications.map(cert => ({
      cert_name: cert.name,
      certificationInstitution: cert.issuer,
      certUrl: cert.url,
      startDate: cert.startDate,
      endDate: cert.endDate,
      candidateID: user.data[0].c_id
    }));

    // Update education, experience, and certifications
    const updatePromises = [
      axios.post(
        process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE,
        { educationdata: formattedEducation, type: "EDU" },
        { headers: { Authorization: token }}
      ),
      axios.post(
        process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE,
        { educationdata: formattedExp, type: "EXP" },
        { headers: { Authorization: token }}
      ),
      axios.post(
        process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE,
        { educationdata: formattedCertifications, type: "CERT" },
        { headers: { Authorization: token }}
      )
    ];

    await Promise.all(updatePromises);

    // Handle portfolio submission
    if (formData.portfolio.length > 0) {
      const portfolioUserId = user.data[0].type === 'Provider' ? user.data[0].id : user.data[0].c_id;
      
      const portfolioPayload = formData.portfolio.map(item => ({
        portfolio_user_id: portfolioUserId,
        project_objective: item.projectObjective,
        project_description: item.projectDescription,
        industry: item.industry,
        project_files: item.images || []
      }));

      await fetch(process.env.REACT_APP_TALENT_CREATEPORTFOLIO, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(portfolioPayload)
      });
    }

    // Update login state and handle success
    await dispatch(login(user.data[0].email, "candidate"));
    setAlertMessage({
      type: 'success',
      message: 'Profile created successfully!'
    });
    history.push('/candidate-dashboard');

  } catch (error) {
    setAlertMessage({
      type: 'error',
      message: error.message || 'Error creating profile. Please try again.'
    });
  } finally {
    setIsLoading(false);
  }
};

// Modified file upload handler to match previous implementation
const handleFileUpload = async (file, type) => {
  try {
    const fileName = file.name;
    const params = {
      Bucket: awsConfig.bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type
    };

    const uploadResult = await s3.upload(params).promise();
    
    // Return the key for resume and assessment, full URL for other types
    return type === 'resume' || type === 'assessment' 
      ? uploadResult.Key 
      : uploadResult.Location;
  } catch (error) {
    console.error(`Error uploading ${type}:`, error);
    throw error;
  }
};

// Modified resume parser to match previous implementation
const handleResumeUpload = async (file) => {
  try {
    setIsLoading(true);
    const uploadedKey = await handleFileUpload(file, 'resume');

    const response = await fetch(process.env.REACT_APP_TALENT_RESUMEPARSAR, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        file_name: file.name
      })
    });

    if (response.ok) {
      const parsedData = await response.json();
      if (parsedData?.body) {
        updateFormDataFromResume(parsedData.body, uploadedKey);
      }
    }
  } catch (error) {
    console.error('Error processing resume:', error);
    setAlertMessage({
      type: 'error',
      message: 'Error processing resume. Please try again.'
    });
  } finally {
    setIsLoading(false);
  }
};

// Helper function to update form data from resume
const updateFormDataFromResume = (data, resumeKey) => {
  setFormData(prev => ({
    ...prev,
    basicInfo: {
      ...prev.basicInfo,
      firstname: data.firstname || prev.basicInfo.firstname,
      middlename: data.middlename || prev.basicInfo.middlename,
      lastname: data.lastname || prev.basicInfo.lastname,
      about: data.about || prev.basicInfo.about,
      resume: resumeKey
    },
    skills: {
      ...prev.skills,
      primaryskill: data.primaryskill || prev.skills.primaryskill,
      secondaryskill: data.secondaryskill || prev.skills.secondaryskill,
      techStack: data.techStack || prev.skills.techStack,
      industry: data.industry || prev.skills.industry,
      job_title: data.job_title || prev.skills.job_title
    },
    education: data.education?.length ? data.education : prev.education,
    experience: data.experience?.length ? data.experience : prev.experience,
    certifications: data.certifications?.length ? data.certifications : prev.certifications
  }));
};

  // Render step content
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicInformation 
            formData={formData.basicInfo}
            handleInputChange={(field, value) => handleInputChange('basicInfo', field, value)}
            handleFileUpload={handleFileUpload}
            setFormData={setFormData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            setAlertMessage={setAlertMessage}
            user={user}
            token={token}
          />
        );
      case 1:
        return (
          <SkillsExpertise 
            formData={formData.skills}
            handleInputChange={(field, value) => handleInputChange('skills', field, value)}
          />
        );
      case 2:
        return (
          <Education 
            education={formData.education}
            handleInputChange={handleArrayFieldChange}
            handleAddItem={() => handleAddItem('education')}
            handleRemoveItem={(index) => handleRemoveItem('education', index)}
          />
        );
      case 3: // Add this case
        return (
          <Certifications 
            certifications={formData.certifications}
            handleInputChange={handleArrayFieldChange}
            handleAddItem={() => handleAddItem('certifications')}
            handleRemoveItem={(index) => handleRemoveItem('certifications', index)}
          />
        );
      case 4:
        return (
          <WorkExperience 
            experience={formData.experience}
            handleInputChange={handleArrayFieldChange}
            handleAddItem={() => handleAddItem('experience')}
            handleRemoveItem={(index) => handleRemoveItem('experience', index)}
          />
        );
      case 5:
        return (
          <Portfolio 
            portfolio={formData.portfolio}
            handleInputChange={handleArrayFieldChange}
            handleAddItem={() => handleAddItem('portfolio')}
            handleRemoveItem={(index) => handleRemoveItem('portfolio', index)}
            handleFileUpload={handleFileUpload}
          />
        );
      case 6:
        return (
          <OtherInformation 
            formData={formData.otherInfo}
            handleInputChange={(field, value) => handleInputChange('otherInfo', field, value)}
          />
        );
      default:
        return 'Unknown step';
    }
  };
  
  // Also modify the handleAddItem function to include certification fields:
  const handleAddItem = (section) => {
    const newItem = {
      id: uuidv4(),
      ...(section === 'education' && {
        degree: '',
        institution: '',
        startDate: '',
        endDate: ''
      }),
      ...(section === 'certifications' && {  // Add this section
        cert_name: '',
        certificationInstitution: '',
        certUrl: '',
        startDate: '',
        endDate: '',
        candidateID: user.data[0].c_id
      }),
      ...(section === 'experience' && {
        work_organization: '',
        work_position: '',
        workStartdate: '',
        workEnddate: '',
        present: false,
        candidateID: user.data[0].c_id
      })
    };
  
    setFormData(prev => ({
      ...prev,
      [section]: [...prev[section], newItem]
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Create Your Profile
        </Typography>

        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {alertMessage.message && (
          <Alert severity={alertMessage.type} sx={{ mb: 2 }}>
            {alertMessage.message}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {getStepContent(activeStep)}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            {activeStep !== 0 && (
              <Button
                onClick={handleBack}
                sx={{ mr: 1 }}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
            )}

            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
              >
                Submit Profile
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                endIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
            )}
          </Box>
        </form>
      </Paper>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          Are you sure you want to submit your profile?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

  // Handle file upload to S3
  const FormTextField = memo(({ label, value, onChange, ...props }) => (
    <TextField
      label={label}
      value={value || ''}
      onChange={onChange}
      {...props}
    />
  ));
// Step Components

 // Memoized Basic Information Form
 const BasicInformation = memo(({ 
  formData, 
  handleInputChange, 
  handleFileUpload,
  setFormData,
  isLoading,
  setIsLoading,
  isUploading,          // Add this line
  setIsUploading,
  setAlertMessage,
  user,
  token
}) => {
  const [previewImage, setPreviewImage] = useState(formData.profilePicture || '');
  const [resumeFile, setResumeFile] = useState(null);
  const [assignmentFile, setAssignmentFile] = useState(null);

  // Resume upload handler with all required dependencies
  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true);
    try {
      // Upload to S3
      const fileName = `${uuidv4()}-${file.name}`;
      const params = {
        Bucket: awsConfig.bucketName,
        Key: fileName,
        Body: file,
        ContentType: file.type
      };

      const uploadResult = await s3.upload(params).promise();
      
      // Call resume parser API
      const response = await fetch(process.env.REACT_APP_TALENT_RESUMEPARSAR, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file_name: fileName,
        }),
      });

      if (response.ok) {
        const parsedData = await response.json();
        console.log('Parsed Resume Data:', parsedData);
        
        if (parsedData?.body) {
          const data = parsedData.body;
          
          setFormData(prevData => ({
            ...prevData,
            basicInfo: {
              ...prevData.basicInfo,
              firstname: data.firstname || prevData.basicInfo.firstname,
              middlename: data.middlename || prevData.basicInfo.middlename,
              lastname: data.lastname || prevData.basicInfo.lastname,
              about: data.about || prevData.basicInfo.about,
              country: data.country || prevData.basicInfo.country,
              work_authorization: data.work_authorization || prevData.basicInfo.work_authorization,
              resume: uploadResult.Key
            },
            skills: {
              ...prevData.skills,
              primaryskill: data.primaryskill || prevData.skills.primaryskill,
              secondaryskill: data.secondaryskill || prevData.skills.secondaryskill,
              techStack: data.techStack || prevData.skills.techStack,
              industry: data.industry || prevData.skills.industry,
              job_title: data.job_title || prevData.skills.job_title
            },
            education: data.education?.map(edu => ({
              id: uuidv4(),
              degree: edu.degree || '',
              institution: edu.institution || '',
              domain: edu.domain || '',
              startDate: edu.startDate || '',
              endDate: edu.endDate || '',
              candidateID: user.data[0].c_id
            })) || prevData.education,
            experience: data.exp?.map(exp => ({
              id: uuidv4(),
              work_organization: exp.work_organization || '',
              work_position: exp.work_position || '',
              workStartdate: exp.workStartdate || '',
              workEnddate: exp.workEnddate || '',
              present: exp.present || false,
              candidateID: user.data[0].c_id
            })) || prevData.experience,
            certifications: data.certifications?.map(cert => ({
              id: uuidv4(),
              cert_name: cert.cert_name || '',
              certificationInstitution: cert.certificationInstitution || '',
              certUrl: cert.certUrl || '',
              startDate: cert.startDate || '',
              endDate: cert.endDate || '',
              candidateID: user.data[0].c_id
            })) || prevData.certifications,
            otherInfo: {
              ...prevData.otherInfo,
              linkedin: data.linkedinurl || prevData.otherInfo.linkedin,
              github: data.githuburl || prevData.otherInfo.github,
              portfolioUrl: data.portfoliourl || prevData.otherInfo.portfolioUrl,
              calendlyUrl: data.calendlyurl || prevData.otherInfo.calendlyUrl,
              timezone: data.timezone || prevData.otherInfo.timezone,
              availability: data.availability || prevData.otherInfo.availability,
              hoursperweek: data.hoursperweek || prevData.otherInfo.hoursperweek,
              ratePerHour: data.ratePerhour || prevData.otherInfo.ratePerHour,
              ratePerMonth: data.ratePermonth || prevData.otherInfo.ratePerMonth,
              currency: data.currency || prevData.otherInfo.currency
            }
          }));
        }
      } else {
        throw new Error('Failed to parse resume');
      }
    } catch (error) {
      console.error('Error processing resume:', error);
      setAlertMessage({
        type: 'error',
        message: 'Error processing resume. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };
// Add a debug useEffect to monitor form data changes
useEffect(() => {
  console.log('Form Data Updated:', formData);
}, [formData]);

  // Assignment upload
  const handleAssignmentUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const fileName = `${uuidv4()}-${file.name}`;
      const params = {
        Bucket: awsConfig.bucketName,
        Key: `assignments/${fileName}`,
        Body: file,
        ContentType: file.type
      };

      const uploadResult = await s3.upload(params).promise();
      handleInputChange('coding_assessment_url', uploadResult.Key);
    } catch (error) {
      console.error('Error uploading assignment:', error);
    } finally {
      setIsUploading(false);
    }
  };
  // Simplified direct event handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(name, value);
  };
  const s3 = new AWS.S3(awsConfig);

  const handleProfilePicture = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      
      try {
        const fileName = file.name;
        const params = {
          Bucket: awsConfig.bucketName,
          Key: fileName,
          Body: file,
          ContentType: file.type
        };

        const { Location } = await s3.upload(params).promise();
        handleInputChange('profilePicture', Location);
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }
  };
  const [image, setImage] = useState("");

  const onImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    
    S3FileUpload.uploadFile(file, config)
      .then(data => {
        handleInputChange('profilePicture', data.key);
        setIsUploading(false);
      })
      .catch(err => {
        setIsUploading(false);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Box position="relative">
          {/* <Avatar
            src={previewImage || formData.profilePicture}
            sx={{ 
              width: 100, 
              height: 100,
              cursor: 'pointer'
            }}
          /> */}
   {image === "" ? (
  <Box
    position="relative"
    width={100}
    height={100}
    borderRadius="50%"
    border="1px solid #8EAEBC"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <label htmlFor="profilePicture" style={{ 
      width: '100%', 
      height: '100%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      cursor: 'pointer' 
    }}>
      <Avatar
        sx={{ 
          width: '100%', 
          height: '100%', 
          bgcolor: 'transparent'
        }}
      >
        <PersonIcon style={{ color: '#8EAEBC' }} />
      </Avatar>
    </label>
    <input
      type="file"
      onChange={onImageUpload}
      id="profilePicture"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer'
      }}
    />
    <label htmlFor="profilePicture">
      <IconButton
        component="span"
        sx={{
          position: 'absolute',
          bottom: -10,
          right: -10,
          backgroundColor: '#03045e',
          color: 'white',
          '&:hover': { backgroundColor: '#03045e' }
        }}
      >
        <PhotoCameraIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </label>
  </Box>
) : (
  <Box position="relative">
    <Avatar
      src={image}
      alt=""
      sx={{
        width: 100,
        height: 100,
        mt: '50px',
        border: '1px solid #8EAEBC'
      }}
    />
    <label htmlFor="profilePicture">
      <IconButton
        component="span"
        sx={{
          position: 'absolute',
          bottom: -10,
          right: -10,
          backgroundColor: '#03045e',
          color: 'white',
          '&:hover': { backgroundColor: '#03045e' }
        }}
      >
        <PhotoCameraIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </label>
    <input
      type="file"
      onChange={onImageUpload}
      id="profilePicture"
      style={{ display: 'none' }}
    />
  </Box>
)}
          {/* <label htmlFor="profile-picture">
            <IconButton
              component="span"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': { backgroundColor: 'primary.dark' }
              }}
            >
              <PhotoCameraIcon />
            </IconButton>
          </label> */}
        </Box>
      </Grid>
 {/* Add Resume Upload Section */}
 <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Resume Upload
        </Typography>
        <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
          <input
            accept=".pdf,.doc,.docx"
            id="resume-upload"
            type="file"
            onChange={handleResumeUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="resume-upload">
            <Button
              variant="outlined"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Upload Resume'}
            </Button>
          </label>
          {formData.resume && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Resume uploaded successfully
            </Typography>
          )}
        </Box>
      </Grid>

      {/* Add Assignment Upload Section */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Coding Assignment
        </Typography>
        <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
          <input
            accept=".pdf,.doc,.docx"
            id="assignment-upload"
            type="file"
            onChange={handleAssignmentUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="assignment-upload">
            <Button
              variant="outlined"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Upload Assignment'}
            </Button>
          </label>
          {formData.coding_assessment_url && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Assignment uploaded successfully
            </Typography>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          name="firstname"
          label="First Name"
          value={formData.firstname || ''}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="middlename"
          label="Middle Name"
          value={formData.middlename || ''}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          name="lastname"
          label="Last Name"
          value={formData.lastname || ''}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <InputLabel>Country</InputLabel>
          <Select
            name="country"
            value={formData.country || ''}
            onChange={handleChange}
            label="Country"
          >
            {COUNTRY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {formData.country === 'usa' && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel>Work Authorization</InputLabel>
            <Select
              name="work_authorization"
              value={formData.work_authorization || ''}
              onChange={handleChange}
              label="Work Authorization"
            >
              {WORK_AUTHORIZATION.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          multiline
          rows={4}
          name="about"
          label="About"
          value={formData.about || ''}
          onChange={handleChange}
          variant="outlined"
          helperText="Tell us about yourself, your experience, and what you're looking for"
        />
      </Grid>
    </Grid>
  );
});

const SkillsExpertise = ({ formData, handleInputChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <InputLabel>Primary Skill</InputLabel>
          <Select
            value={formData.primaryskill}
            onChange={(e) => handleInputChange('primaryskill', e.target.value)}
            label="Primary Skill"
          >
            <MenuItem value="html">HTML</MenuItem>
            <MenuItem value="css">CSS</MenuItem>
            <MenuItem value="JavaScript">JavaScript</MenuItem>
            <MenuItem value="ReactJS">ReactJS</MenuItem>
            <MenuItem value="NodeJS">NodeJS</MenuItem>
            <MenuItem value="Python">Python</MenuItem>
            {/* Add more skills as needed */}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Secondary Skill</InputLabel>
          <Select
            value={formData.secondaryskill}
            onChange={(e) => handleInputChange('secondaryskill', e.target.value)}
            label="Secondary Skill"
          >
            <MenuItem value="Python">Python</MenuItem>
            <MenuItem value="Java">Java</MenuItem>
            <MenuItem value="SQL">SQL</MenuItem>
            <MenuItem value="AWS">AWS</MenuItem>
            {/* Add more skills as needed */}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl fullWidth required>
          <InputLabel>Tech Stack</InputLabel>
          <Select
            value={formData.techStack}
            onChange={(e) => handleInputChange('techStack', e.target.value)}
            label="Tech Stack"
          >
            {TECH_STACK.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl fullWidth required>
          <InputLabel>Industry</InputLabel>
          <Select
            value={formData.industry}
            onChange={(e) => handleInputChange('industry', e.target.value)}
            label="Industry"
          >
            {INDUSTRY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl fullWidth required>
          <InputLabel>Job Title</InputLabel>
          <Select
            value={formData.job_title}
            onChange={(e) => handleInputChange('job_title', e.target.value)}
            label="Job Title"
          >
            {JOB_TITLE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const Education = ({ education, handleInputChange, handleAddItem, handleRemoveItem }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Education Details</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddItem}
        >
          Add Education
        </Button>
      </Box>

      {education.map((edu, index) => (
        <Card key={edu.id} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <IconButton 
                  color="error" 
                  onClick={() => handleRemoveItem(index)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Degree</InputLabel>
                  <Select
                    value={edu.degree}
                    onChange={(e) => handleInputChange('education', index, 'degree', e.target.value)}
                    label="Degree"
                  >
                    <MenuItem value="Bachelors">Bachelors</MenuItem>
                    <MenuItem value="Masters">Masters</MenuItem>
                    <MenuItem value="PHD">PHD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Institution"
                  value={edu.institution}
                  onChange={(e) => handleInputChange('education', index, 'institution', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Start Date"
                  type="date"
                  value={edu.startDate}
                  onChange={(e) => handleInputChange('education', index, 'startDate', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="End Date"
                  type="date"
                  value={edu.endDate}
                  onChange={(e) => handleInputChange('education', index, 'endDate', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};
const Certifications = ({ certifications, handleInputChange, handleAddItem, handleRemoveItem }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Certifications</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddItem}
        >
          Add Certification
        </Button>
      </Box>

      {certifications.map((cert, index) => (
        <Card key={cert.id} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <IconButton 
                  color="error" 
                  onClick={() => handleRemoveItem(index)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Certification Name"
                  value={cert.cert_name || ''}
                  onChange={(e) => handleInputChange('certifications', index, 'cert_name', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Certification Institution"
                  value={cert.certificationInstitution || ''}
                  onChange={(e) => handleInputChange('certifications', index, 'certificationInstitution', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Certification URL"
                  type="url"
                  value={cert.certUrl || ''}
                  onChange={(e) => handleInputChange('certifications', index, 'certUrl', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Start Date"
                  type="date"
                  value={cert.startDate || ''}
                  onChange={(e) => handleInputChange('certifications', index, 'startDate', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="End Date"
                  type="date"
                  value={cert.endDate || ''}
                  onChange={(e) => handleInputChange('certifications', index, 'endDate', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};
const WorkExperience = ({ experience, handleInputChange, handleAddItem, handleRemoveItem }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Work Experience</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddItem}
        >
          Add Experience
        </Button>
      </Box>

      {experience.map((exp, index) => (
        <Card key={exp.id} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <IconButton 
                  color="error" 
                  onClick={() => handleRemoveItem(index)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Company/Organization"
                  value={exp.work_organization}
                  onChange={(e) => handleInputChange('experience', index, 'work_organization', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Position"
                  value={exp.work_position}
                  onChange={(e) => handleInputChange('experience', index, 'work_position', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Start Date"
                  type="date"
                  value={exp.workStartdate}
                  onChange={(e) => handleInputChange('experience', index, 'workStartdate', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center" gap={2}>
                  <TextField
                    fullWidth
                    label="End Date"
                    type="date"
                    value={exp.workEnddate}
                    onChange={(e) => handleInputChange('experience', index, 'workEnddate', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    disabled={exp.present}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={exp.present}
                        onChange={(e) => handleInputChange('experience', index, 'present', e.target.checked)}
                      />
                    }
                    label="Present"
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const Portfolio = ({ portfolio, handleInputChange, handleAddItem, handleRemoveItem, handleFileUpload }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Portfolio Projects</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddItem}
        >
          Add Project
        </Button>
      </Box>

      {portfolio.map((project, index) => (
        <Card key={project.id} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <IconButton 
                  color="error" 
                  onClick={() => handleRemoveItem(index)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Project Objective"
                  value={project.projectObjective}
                  onChange={(e) => handleInputChange('portfolio', index, 'projectObjective', e.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  multiline
                  rows={3}
                  label="Project Description"
                  value={project.projectDescription}
                  onChange={(e) => handleInputChange('portfolio', index, 'projectDescription', e.target.value)}
                  inputProps={{ maxLength: 300 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Industry</InputLabel>
                  <Select
                    value={project.industry}
                    onChange={(e) => handleInputChange('portfolio', index, 'industry', e.target.value)}
                    label="Industry"
                  >
                    {INDUSTRY_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Project Images (Max 3)
                  </Typography>
                  <label htmlFor={`project-images-${index}`}>
                    <Input
                      accept="image/*"
                      id={`project-images-${index}`}
                      multiple
                      type="file"
                      onChange={async (e) => {
                        const files = Array.from(e.target.files).slice(0, 3);
                        const urls = await Promise.all(
                          files.map(file => handleFileUpload(file, 'portfolio'))
                        );
                        handleInputChange('portfolio', index, 'images', urls);
                      }}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Images
                    </Button>
                  </label>
                  {project.images && project.images.length > 0 && (
                    <Box display="flex" gap={1} mt={2}>
                      {project.images.map((img, imgIndex) => (
                        <Box
                          key={imgIndex}
                          position="relative"
                          width={100}
                          height={100}
                        >
                          <img
                            src={img}
                            alt={`Project ${index} - Image ${imgIndex + 1}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: 4
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: -8,
                              right: -8,
                              backgroundColor: 'background.paper'
                            }}
                            onClick={() => {
                              const newImages = project.images.filter((_, i) => i !== imgIndex);
                              handleInputChange('portfolio', index, 'images', newImages);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const OtherInformation = ({ formData, handleInputChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Professional Links
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="LinkedIn URL"
          value={formData.linkedin}
          onChange={(e) => handleInputChange('linkedin', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkedInIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Portfolio URL"
          value={formData.portfolioUrl}
          onChange={(e) => handleInputChange('portfolioUrl', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Availability & Preferences
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <InputLabel>Time Zone</InputLabel>
          <Select
            value={formData.timezone}
            onChange={(e) => handleInputChange('timezone', e.target.value)}
            label="Time Zone"
          >
            <MenuItem value="Indian Standard time">Indian Standard Time</MenuItem>
            <MenuItem value="Eastern standard time">Eastern Standard Time</MenuItem>
            <MenuItem value="Central standard time">Central Standard Time</MenuItem>
            <MenuItem value="Pacific standard time">Pacific Standard Time</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <InputLabel>Availability</InputLabel>
          <Select
            value={formData.availability}
            onChange={(e) => handleInputChange('availability', e.target.value)}
            label="Availability"
          >
            <MenuItem value="full time">Full Time</MenuItem>
            <MenuItem value="part time">Part Time</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {formData.availability === 'part time' && (
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label="Hours Per Week"
            type="number"
            value={formData.hoursperweek}
            onChange={(e) => handleInputChange('hoursperweek', e.target.value)}
            InputProps={{ inputProps: { min: 1, max: 40 } }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Rate Information
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          required
          label="Rate Per Hour"
          type="number"
          value={formData.ratePerHour}
          onChange={(e) => handleInputChange('ratePerHour', e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          required
          label="Rate Per Month"
          type="number"
          value={formData.ratePerMonth}
          onChange={(e) => handleInputChange('ratePerMonth', e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl fullWidth required>
          <InputLabel>Currency</InputLabel>
          <Select
            value={formData.currency}
            onChange={(e) => handleInputChange('currency', e.target.value)}
            label="Currency"
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="INR">INR</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Calendly URL"
          value={formData.calendlyUrl}
          onChange={(e) => handleInputChange('calendlyUrl', e.target.value)}
          helperText="Share your Calendly link for easy scheduling"
        />
      </Grid>
    </Grid>
  );
};

export default Createyourprofile;