import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Modal, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Typography,
  Select,
  MenuItem,
  Card,
  CircularProgress,
  CardContent,
  Container,
  styled
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'box-shadow 0.3s',
  width: '300px',
  '&:hover': {
    boxShadow: theme.shadows[4]
  }
}));

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  width: '75%',
  maxHeight: '80vh',
  overflowY: 'auto'
}));
const LoadingContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '200px'
  });
  

const CardTasks = ({ initialPodIds, selectedSprint }) => {
  const [metrics, setMetrics] = useState(null);
  const [selectedTalent, setSelectedTalent] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchMetrics();
  }, [initialPodIds, selectedSprint]);

  const fetchMetrics = async () => {
    console.log('Fetch Metrics Called', {
      token: !!token,
      apiEndpoint: process.env.REACT_APP_CLIENT_PODTASKSMETRICSMANAGER,
      initialPodIds,
      managerId: user?.data?.[0]?.emp_id
    });
    
    if (!token || !process.env.REACT_APP_CLIENT_PODTASKSMETRICSMANAGER) {
      console.error('Missing token or API endpoint');
      setIsLoading(false);
      return;
    }
  
    try {
      console.log('Sending request with payload:', {
        manager_id: user.data[0].emp_id,
        pod_id: initialPodIds,
      });
  
      const response = await fetch(process.env.REACT_APP_CLIENT_PODTASKSMETRICSMANAGER, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          pod_id: initialPodIds,
        }),
      });
  
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Full response:', data);
  
      const { body: parsedData } = data;
      
      if (parsedData?.status_counts && parsedData?.pod_tasks_by_talent) {
        setMetrics(parsedData);
        setTaskData(parsedData.pod_tasks || []);
      } else {
        console.warn('No data in response', parsedData);
        setMetrics(null);
        setTaskData([]);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setMetrics(null);
      setTaskData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getDisplayData = () => {
    if (!metrics) return { todo: 0, done: 0 };

    if (selectedTalent) {
      const talentData = metrics.pod_tasks_by_talent.find(t => t.Talent_name === selectedTalent);
      return {
        todo: talentData?.Open || 0,
        done: talentData?.Complete || 0
      };
    }

    return {
      todo: metrics.status_counts.Open || 0,
      done: metrics.status_counts.Complete || 0
    };
  };

  return (
  <Box sx={{ height: '100%' }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center"
        sx={{ py: 3 }}
      >
        <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ fontWeight: 'medium', mb: 3 }}
        >
          {intl.formatMessage({ id: 'Tasks' })}
        </Typography>

        <Select
          value={selectedTalent}
          onChange={(e) => setSelectedTalent(e.target.value)}
          sx={{ 
            width: 240, 
            mb: 3,
            '& .MuiSelect-select': {
              py: 1.5
            }
          }}
          displayEmpty
        >
          <MenuItem value="">
            {intl.formatMessage({ id: 'allReporters' })}
          </MenuItem>
          {metrics?.pod_tasks_by_talent?.map((talent) => (
            <MenuItem key={talent.Talent_name} value={talent.Talent_name}>
              {talent.Talent_name}
            </MenuItem>
          ))}
        </Select>

        <StyledCard onClick={() => setModalOpen(true)}>
          <CardContent sx={{ p: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <FormatListBulletedIcon 
                style={{ 
                  width: 32, 
                  height: 32, 
                  marginRight: 12, 
                  color: '#4caf50'
                }} 
              />
              <Typography variant="h6"   sx={{fontWeight: 600,
      letterSpacing: '0.5px',
      backgroundColor: 'green',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'}}>POD Tasks</Typography>
            </Box>
             {isLoading ? (
                                          <LoadingContainer>
                                            <CircularProgress />
                                          </LoadingContainer>
                                        ) : (
                                          <>
            <Box sx={{ mt: 5.5}}>
              <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center"
                mb={1.5}
              >
                <Typography color="text.secondary">TO DO:</Typography>
                <Typography 
                  fontWeight="bold"
                  sx={{ ml: 2 }}
                >
                  {getDisplayData().todo}
                </Typography>
              </Box>
              <Box 
                display="flex" 
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="text.secondary">DONE:</Typography>
                <Typography 
                  fontWeight="bold"
                  sx={{ ml: 2 }}
                >
                  {getDisplayData().done}
                </Typography>
              </Box>
            </Box>
           </> )}
          </CardContent>
        </StyledCard>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="tasks-modal-title"
      >
        <StyledModalBox>
          <Typography 
            id="tasks-modal-title" 
            variant="h6" 
            sx={{ 
              mb: 3,
              fontWeight: 'medium'
            }}
          >
            Task Details
          </Typography>
          <TableContainer 
            component={Paper}
            sx={{ maxHeight: '60vh' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[
                    'candidateName',
                    'projectKey',
                    'podTaskName',
                    'category',
                    'issueType',
                    'status'
                  ].map((id) => (
                    <TableCell 
                      key={id}
                      sx={{ 
                        bgcolor: 'primary.light',
                        fontWeight: 'medium'
                      }}
                    >
                      {intl.formatMessage({ id })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {taskData.map((task, index) => (
                  <TableRow 
                    key={index}
                    sx={{ 
                      '&:nth-of-type(odd)': { 
                        bgcolor: 'action.hover' 
                      },
                      '&:hover': {
                        bgcolor: 'action.selected'
                      }
                    }}
                  >
                    <TableCell>{task.candidate_name}</TableCell>
                    <TableCell>{task.project_key}</TableCell>
                    <TableCell>{task.pod_task_name}</TableCell>
                    <TableCell>{task.category}</TableCell>
                    <TableCell>{task.issuetype}</TableCell>
                    <TableCell>{task.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledModalBox>
      </Modal>
    </Box>
  );
};

CardTasks.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
  selectedSprint: PropTypes.string
};

export default CardTasks;